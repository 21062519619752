import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "flex flex-column md:flex-row gap-4" }
const _hoisted_3 = { class: "w-full md:w-3 xl:w-2 xl:p-3" }
const _hoisted_4 = { class: "md:w-9 xl:w-10 xl:p-3" }
const _hoisted_5 = { class: "w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryDebug = _resolveComponent("SummaryDebug")!
  const _component_MailSidebar = _resolveComponent("MailSidebar")!
  const _component_MailTypes = _resolveComponent("MailTypes")!
  const _component_MailDetail = _resolveComponent("MailDetail")!
  const _component_DynamicEmail = _resolveComponent("DynamicEmail")!
  const _component_Reply = _resolveComponent("Reply")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.loaded && _ctx.getPropertyBooleanValue(_ctx.BaseControlTypeConst.DEBUG,_ctx.Component))
      ? (_openBlock(), _createBlock(_component_SummaryDebug, {
          key: 0,
          data: _ctx.Component,
          vmodel: _ctx.vmodel
        }, null, 8 /* PROPS */, ["data", "vmodel"]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_MailSidebar, {
            items: _ctx.sidebarItems,
            activeMailItem: _ctx.activeMailItem,
            onNavigate: _ctx.handleNavigate
          }, null, 8 /* PROPS */, ["items", "activeMailItem", "onNavigate"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.sections.inbox)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 0,
                  mails: _ctx.allMails,
                  totalMails: _ctx.mailsCount,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getMails,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "totalMails", "onNavigate", "onPageChange", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.draft)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 1,
                  mails: _ctx.allDrafts,
                  totalMails: _ctx.draftsCount,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getDrafts,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "totalMails", "onNavigate", "onPageChange", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.trash)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 2,
                  mails: _ctx.allTrash,
                  totalMails: _ctx.trashCount,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getTrash,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "totalMails", "onNavigate", "onPageChange", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.archived)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 3,
                  mails: _ctx.allArchived,
                  totalMails: _ctx.archivedCount,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getArchived,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "totalMails", "onNavigate", "onPageChange", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.sent)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 4,
                  mails: _ctx.allSent,
                  totalMails: _ctx.sentCount,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getSent,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "totalMails", "onNavigate", "onPageChange", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.spam)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 5,
                  mails: _ctx.allSpam,
                  totalMails: _ctx.spamCount,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getSpam,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "totalMails", "onNavigate", "onPageChange", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.starred)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 6,
                  mails: _ctx.allStarred,
                  totalMails: _ctx.starredCount,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getStarred,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "totalMails", "onNavigate", "onPageChange", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.important)
              ? (_openBlock(), _createBlock(_component_MailTypes, {
                  key: 7,
                  mails: _ctx.allImportant,
                  totalMails: _ctx.importantCount,
                  onNavigate: _ctx.handleNavigate,
                  onPageChange: _ctx.getImportants,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "totalMails", "onNavigate", "onPageChange", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.detail)
              ? (_openBlock(), _createBlock(_component_MailDetail, {
                  key: 8,
                  mails: _ctx.getDetailMails(),
                  emailId: _ctx.emailId,
                  onNavigate: _ctx.handleNavigate,
                  onTrash: _ctx.moveToTrash,
                  onArchive: _ctx.moveToArchive,
                  onSpam: _ctx.moveToSpam
                }, null, 8 /* PROPS */, ["mails", "emailId", "onNavigate", "onTrash", "onArchive", "onSpam"]))
              : _createCommentVNode("v-if", true),
            (_ctx.sections.compose)
              ? (_openBlock(), _createBlock(_component_DynamicEmail, {
                  key: 9,
                  paramEmail: _ctx.emailId != null ? _ctx.allDrafts.find(d => d.id == _ctx.emailId): undefined,
                  container: _ctx.container,
                  Component: _ctx.Component,
                  slotProps: _ctx.slotProps,
                  onNavigate: _ctx.handleNavigate
                }, null, 8 /* PROPS */, ["paramEmail", "container", "Component", "slotProps", "onNavigate"]))
              : _createCommentVNode("v-if", true)
          ])
        ])
      ])
    ]),
    _createVNode(_component_Reply, {
      visible: _ctx.dialogVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dialogVisible) = $event)),
      "mail-detail": _ctx.mailDetail,
      onSave: _ctx.onSaveReplyMail,
      "onUpdate:dialogVisible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeDialogVisibility()))
    }, null, 8 /* PROPS */, ["visible", "mail-detail", "onSave"])
  ], 64 /* STABLE_FRAGMENT */))
}