
/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed, ref, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import { Container } from 'inversify';
import Environment from '../../../../../common/infrastructure/funciones/environment'
import { appConfig } from "../../domain/appConfig";
import Button from 'primevue/button'
import MessageService, { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import { helpers, required } from "@vuelidate/validators";
import { resetPass } from "../../domain/resetPass";
import useVuelidate from "@vuelidate/core";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import {
  Actions,
  Mutations,
} from "../../../../../common/infrastructure/almacen/enums/StoreEnums";
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';
import { msalInstance, initializeMsalInstance, authMicrosoft } from "./authConfig";
import { AuthenticationResult, AccountInfo } from "@azure/msal-browser";

declare const google: any;

const __default__ = {
  components: { Button },
  name: 'forgotpassword',
  props:
    {
        container: {
            type: Object as () => Container
        },
        getLogoUrl: {
            type: String,
            default: ''
        },
        appCfg: {
          type: Object as () => appConfig
        },
    },
  emits: ['isForgetPassChild'],
  setup (props, { emit }) {
    const store = useStore()
    const emailControl = ref < HTMLElement | null > (null)
    const emailConfirmControl = ref < HTMLElement | null > (null)
    const email = ref('')
    const emailconfirm = ref('')
    const ipublicPath = inject('publicPath')
    const submitted = ref(false);
    
    let my_color1 = props.appCfg.color1;
    let my_color2 = props.appCfg.color2;

    const signinWiths = computed(() => {
        return store.getters.getAppConfig.filter((x) => x.idKeyType == CatalogConfigurationConst.SIGNINWITH)[0]?.keyValue ?? '';
    });

    /* const user_req: Partial<Usuario> = {
            email: ''
        };

        const request = ref<forgetPasswordRequest>({
            user: user_req as any,
            remoteApplication: 'Portal',
            remoteIp: '',
            requestedUrl: '',
        }); */

    function validateData () {
        if (!validateEmail(email.value)) {
          MessageService.showMessage(MessageType.Error, 'Error de validación', 'Debe indicar un e-mail válido.');
          return false;
        }
        if (request.value.emailconfirm === null || request.value.emailconfirm === '' ||
            request.value.email === null || request.value.email === ''
        ) {
          return false
        }
        if (request.value.email != request.value.emailconfirm) {
          MessageService.showMessage(MessageType.Error, 'Error de validación', 'Las direcciones de e-mail no coinciden.');
          return false;
        }

      return true
    }

    const back = (event: any) => {
      
      emit('isForgetPassChild', false);
    }

    function validateEmail (email: string) {
      return HelperCommon.validarEmail(email)
    } 

    const onSubmitForgotPassword = () => {
      submitted.value = true;
      if (validateData()) {
        forgotPassword(null, null);
      }
    }

    function forgotPassword(microsoftEmail: string | null, googleEmail: string | null) {
      store.dispatch(Actions.FORGOT_PASSWORD, [request.value.email, microsoftEmail, googleEmail, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
          MessageService.showToast(MessageType.Correcto, '', 'Se ha enviado el correo de reestablecimiento de contraseña correctamente.');
          emit('isForgetPassChild', false);
          //router.push({ name: 'forgotpassword' });
      }).finally(() => {
          HelperLoading.hideLoading();
      });
    }

    const getLogoUrl = computed(() => {
      return Environment.URL_LOGO_PNG;      
    })

    const request = ref<resetPass>({
      email: "",
      emailconfirm: "",
      password: "",
      confirmpass: ""
    });

    const customEmail = (value: string) => {
      return HelperCommon.validarEmail(value);
    };

    const rules = {
      email: {
        required: helpers.withMessage("El email es obligatorio", required),
        customEmail: helpers.withMessage("Correo no válido", customEmail)
      },
      emailconfirm: {
        required: helpers.withMessage("Confirmar el email es obligatorio", required),
        customEmail: helpers.withMessage("Correo no válido", customEmail),
      },
    };

    const v$ = useVuelidate(rules, request);

    const vincularMicrosoft = async () => {
      try {
        submitted.value = true;
        if (validateData()) {
          var authenticatedAccount = await authMicrosoft();
          if (authenticatedAccount && authenticatedAccount.idToken) {
            forgotPassword(authenticatedAccount.username, null);
          }
        }
      } catch (error) {
          console.error("Error al iniciar sesión", error);
      }
    };

    const vincularGoogle = async () => {
      submitted.value = true;
      if (validateData()) {
        if (typeof google === 'undefined') {
            console.error('Google Identity Services not loaded');
            return;
        }

        google.accounts.id.initialize({
            client_id: process.env.VUE_APP_GOOGLE_APPID,
            ux_mode: 'popup', // Puedes usar 'redirect' si prefieres una redirección en lugar de un popup
            scope: 'profile email', // Añade los scopes necesarios
            callback: handleCredentialResponse
        });

        google.accounts.id.prompt(); // Mostrar el prompt de inicio de sesión
      }
    }

    function handleCredentialResponse(response: any) {
        console.log('ID Token: ', response.credential);
        // Procesar el token de ID aquí
        try {
            var token = response.credential;
            const base64Url = token.split('.')[1]; // El payload del JWT está en la segunda parte del token
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            if (response && response.credential) {
              forgotPassword(null, (JSON.parse(jsonPayload)).email);
            }

        } catch (error) {
            console.error("Error al iniciar sesión", error);
        }
    }

    onMounted(async () => {
        await initializeMsalInstance();
        // Verificar si el usuario ya está autenticado al cargar la aplicación
        const account = msalInstance.getAllAccounts()[0];
    })

    return {
      emailControl,
      emailConfirmControl,
      emailconfirm,
      email,
      onSubmitForgotPassword,
      getLogoUrl,
      ipublicPath,
      my_color1,
      my_color2,
      back,
      v$,
      submitted,
      request,
      signinWiths,
      CatalogConfigurationConst,
      vincularMicrosoft,
      vincularGoogle,
    }
  }
}

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7c564416-my_color1": (_ctx.my_color1),
  "7c564416-my_color2": (_ctx.my_color2)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__