<template>
       <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"

            />
    <RadioButton v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)" 
        :key="componentKey"
        :id="getPropertyValue(RadioButtonTypeConst.ID)" v-tooltip="getPropertyValue(RadioButtonTypeConst.TOOLTIP)"
        :style="getPropertyValue(RadioButtonTypeConst.STYLE)"
        :class="{ [getPropertyValue(RadioButtonTypeConst.CLASS) ?? '']: true, 'p-invalid': v$.vmodel.$error && submitted,'p-readonly': getPropertyBooleanValue(RadioButtonTypeConst.READONLY) && !canDoOperation(ObjectGroupConst.EDIT) }"
        :name="getPropertyValue(RadioButtonTypeConst.NAME)"
        :disabled="getPropertyBooleanValue(RadioButtonTypeConst.DISABLED) || (!canEdit)"
        :visible="getPropertyBooleanValue(RadioButtonTypeConst.VISIBLE) && canDoOperation(ObjectGroupConst.VIEW)"
        :placeholder="getPropertyValue(RadioButtonTypeConst.PLACEHOLDER)"
        :value="getPropertyValue(RadioButtonTypeConst.VALUE)" :inputId="getPropertyValue(RadioButtonTypeConst.INPUTID)"
        v-model="vmodel" >
    </RadioButton>
    <CustomValidate v-model:submitted="submitted" v-model:vObject="v$" />
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, onBeforeUnmount, onUnmounted } from 'vue';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import RadioButtonTypeConst from '../../../../domain/Constants/RadioButtonTypeConst';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';
import CustomValidate from '../../shared/CustomValidate.vue';
import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import SummaryDebug from '../../shared/SummaryDebug.vue';

export default defineComponent({
    name: 'dynamic_radio_button',
    components: {
        CustomValidate,
        SummaryDebug
    },
    props:
    {

        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },       
        slotProps: {
            type: Object,
            default: () => ({})
        },      
    },
    setup(props, context) {
        //
        	
        const store = useStore();
        const { vmodel,canDoOperation, getPropertyValue, getPropertyBooleanValue, loaded, resolveExpressions,
             baseOnUnmount, baseOnMounted, baseOnBeforeUnmount, v$, submitted, canEdit,isValidData
             ,fieldKey,fieldKeyComponentDataSourceId ,componentKey
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);



        onMounted(() => {

            baseOnMounted();

        })

        onBeforeUnmount(() => {

            baseOnBeforeUnmount();
        })


        onUnmounted(() => {

            baseOnUnmount();

        })

        return {
            getPropertyValue,
            getPropertyBooleanValue,
            vmodel,
            RadioButtonTypeConst,
            loaded,
            BaseControlTypeConst,
            v$,
            submitted,
            canEdit,
            isValidData,
            fieldKey,
            fieldKeyComponentDataSourceId,
            ObjectGroupConst
            ,canDoOperation
            ,componentKey

        };
    },


});
</script>
<style scoped></style>
