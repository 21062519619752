<template>
    <div class="containerst">
        <DataTable ref="dt" v-model:expandedRowGroups="expandedRowGroups" v-model:selection="selectedItems"
            :value="data" editMode="cell" :lazy="lazy" @cell-edit-init="onCellEditInit($event)"
            @cell-edit-cancel="onCellEditCancel($event)" @cell-edit-complete="onCellEditComplete($event)"
            tableClass="editable-cells-table" v-model:filters="filters"
            :paginator="!lazyScroll ? lazy ? true : showPaginator : lazy ? false : showPaginator" :rows="10"
            :first="first" :totalRecords="totalRecords" class="p-datatable-gridlines" :dataKey="getDataKeyName()"
            :rowHover="true" :onPage="dataTablePageEvent" :onSort="dataTableSortEvent" :onFilter="dataTableFilterEvent"
            :filterDisplay="filterDisplay" responsiveLayout="scroll" sortMode="multiple" resizableColumns
            columnResizeMode="expand" :rowClass="rowClass" :rowStyle="rowStyle" :multiSortMeta="sortConfig"
            :selectAll="selectAll" :virtualScrollerOptions="lazy && lazyScroll ? lazyScrollConfig : undefined"
            :rowGroupMode="rowGroupMode ?? undefined" :groupRowsBy="getGroupRowsBy"
            :expandableRowGroups="expandableRowGroups"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="filasPorPagina" :reorderableColumns="true"
            :scrollable="totalRecords == 0 && lazyScroll ? false : true" :scrollHeight="lazyScroll ? '600px' : '100vh'"
            removableSort currentPageReportTemplate="Mostrando de {first} a {last} de {totalRecords} registros"
            :exportFilename="Component.name??'ResultadoBusqueda'" :csvSeparator="';'" @update:filters="onFilterUpdate($event)"
            @select-all-change="onSelectAllChange($event)">
            <template #header v-if="showHeader">
                <SearchDetail_Header />
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters['global'].value" placeholder="Buscar..."
                            @update:modelValue="globalFilterSearch" />
                        <span class="p-input-icon-right" style="display: inline;">
                            <i class="pi pi-times" v-tooltip.top="'Borrar búsqueda'" @click="cleanGlobalSearch" />
                        </span>
                        <TableExport v-if="canExport" v-model:table="dt" :filename="Component.name??'ResultadoBusqueda'" :lazy="lazy"
                            :moneyColumns="moneyColumns()" @selectAll="resolveSelectedAll(false)"></TableExport>

                        <MultiSelect v-model="selectedColumns" :options="getData" filter optionLabel="label"
                            style="margin-left: 10px;" optionGroupLabel="header" optionValue="value"
                            optionGroupChildren="items" :maxSelectedLabels="5" @update:modelValue="onToggle"
                            display="chip" placeholder="Selecciona un filtro" class="w-full md:w-20rem">
                            <template #optiongroup="slotProps">
                                <div class="flex align-items-center">
                                    <i :class="slotProps.option.icon"></i>
                                    <div class="ml-2">{{ slotProps.option.label }}</div>
                                </div>
                            </template>
                        </MultiSelect>
                        <Button v-if="!showSearch" id="refresh" icon="pi pi-refresh"
                            class="ml-2  p-button-rounded p-button-primary p-button-outlined" @click="doRefreshData" />
                        <Button v-if="canAddNewItem && canDoAction(ObjectGroupConst.ADD)" id="insertar"
                            :label="t('btn.nuevoreg')" icon="pi pi-plus"
                            class="ml-2  p-button-rounded p-button-primary p-button-outlined" @click="toggleNew" />
                        <Button v-if="canImport && canDoAction(ObjectGroupConst.IMPORT)" id="import"
                            :label="t('btn.import')" icon="pi pi-file-import"
                            class="ml-2  p-button-rounded p-button-primary p-button-outlined" @click="toggleImport" />
                        <Button
                            v-if="canUpdateMassive && selectedItems.length > 0 && canDoAction(ObjectGroupConst.EDIT)"
                            id="updatemasive" :label="t('btn.updateMassive')" icon="pi pi-save"
                            class="ml-2  p-button-rounded p-button-primary p-button-outlined"
                            @click="toggleUpdateMassive" />
                        <Button v-if="canClearAllFilters" type="button" icon="pi pi-filter-slash"
                            class="ml-2  p-button-rounded p-button-primary p-button-outlined" label="Borrar" outlined
                            @click="clearFilter()" />
                        <!-- Botones en los resultados-->

                        <slot name="buttonsresult"></slot>


                    </span>

                </div>

            </template>

            <template #groupheader="slotProps">
                <span class="align-middle ml-2 font-bold leading-normal">{{ slotProps.data[getGroupRowsBy] }}</span>
            </template>


            <template #empty> No se han encontrado datos para los filtros indicados </template>

            <SearchDetail_Columns />

            <Column v-if="(canUpdateMassive && canDoAction(ObjectGroupConst.EDIT)) || canSelectMultiple"
                selectionMode="multiple" headerStyle="width: 3rem" :exportable="false" field="multiplerows" frozen>
                <template #loading v-if="lazyScroll">
                    <div class="flex align-items-center"
                        :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="40%" height="1rem" />
                    </div>
                </template>
            </Column>

            <Column
                v-if="menuActionItems?.filter(x => x.visible)?.length > 0 || (showActionDetail && canDoAction(ObjectGroupConst.VIEW)) || (canDeleteItem && canDoAction(ObjectGroupConst.DELETE))"
                field="acciones" header="Acciones" :sortable="false" :exportable="false" frozen
                style="width: 20px; !important">
                <template #body="{ data }">

                    <div class="flex centercontent">
                        <Menu v-if="menuActionItems?.filter(x => x.visible)?.length > 0"
                            :ref="el => { createMenuData(getDataKey(data), el) }" :model="buildActionMenu(data)"
                            :popup="true" :key="'mnu' + getDataKey(data)">
                        </Menu>

                        <i v-if="menuActionItems?.filter(x => x.visible)?.length > 0" class="pi pi-cog"
                            v-tooltip="'Acciones'" style="font-size: 1.5rem" @click="toggleMenu($event, data)"></i>
                        <i v-if="showActionDetail && canDoAction(ObjectGroupConst.VIEW)" class="pi pi-eye ml-2"
                            style="font-size: 1.5rem" @click="verDetalle(data)" v-tooltip="'Ver detalle'"></i>
                        <i v-if="canDeleteItem && canDoAction(ObjectGroupConst.DELETE)" class="pi pi-times ml-2"
                            v-tooltip="'Eliminar registro'" style="color:#D32F2F;font-size: 1.5rem"
                            @click="deleteItem(data)"></i>

                    </div>
                </template>
                <template #loading v-if="lazyScroll">
                    <div class="flex align-items-center"
                        :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="40%" height="1rem" />
                    </div>
                </template>
            </Column>

            <Column v-for="(col, index)  of columnsFilteredList"
                :key="col.field + DataSourceConst.DELIMITERFIELDS + index" :field="getFieldName(col)"
                :filterField="getFilterFieldName(col)" :showFilterMatchModes="showFilterMatchMode(col.id)"
                :filterMenuStyle="{ 'width': '14rem' }" :sortable="sortType(col) ? true : false" :header="col.header"
                :showFilterMenu="filterDisplay == 'menu' ? true : isColumnFilterOption(col.id)"
                :exportable="!isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.IMG) && !isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.URL_IMG) && !isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.URL_IMG_EXT)"
                :dataType="dataTypeFilterControl(col.id)" :bodyStyle="cellStyle(col.id, true)" :maxConstraints="10">
                <template #header>
                    <i v-if="isEdit(col.field) && canDoAction(ObjectGroupConst.EDIT)"
                        class="fa-solid fa-pen-to-square mr-2"></i>
                </template>
                <template #loading v-if="lazyScroll">
                    <div class="flex align-items-center"
                        :style="{ height: '17px', 'flex-grow': '1', overflow: 'hidden' }">
                        <Skeleton width="40%" height="1rem" />
                    </div>
                </template>
                <template #body="slotProps">
                    <div class="flex" :style="cellStyle(col.id, false)">
                        <div v-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.IMG)">
                            <img :src="builUrlImageData(slotProps.data[col.field])" alt="logo" width="50"
                                @error="noImageUrl($event)" />
                        </div>
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.URL_IMG)">
                            <img :src="builUrlImage(slotProps.data[col.field])" alt="logo" width="50"
                                @error="noImageUrl($event)" />
                        </div>
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.URL_IMG_EXT)">
                            <img :src="slotProps.data[col.field]" alt="logo" width="50" @error="noImageUrl($event)" />
                        </div>
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.RPTLAUNCHERLINK)">
                            <Button icon="pi pi-file-pdf" link @click="openReportLauncher(slotProps.data[col.field])"
                                size="large" />
                        </div>
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.EXTERNALLINK)">
                            <Button label="Link" link @click="openLink(slotProps.data[col.field])" />
                        </div>
                        <div v-else-if="hasLookUpByFieldId(col.field)">
                            {{ slotProps?.data ? slotProps.data[col.field +
                                DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION] : null }}
                        </div>
                        <div v-else>
                            <span v-if="hasMenuItemLinkedField(col.id)">
                                <a href="#" class=" font-bold"
                                    @click.stop="clickMenuItem(hasMenuItemLinkedField(col.id), slotProps.data)">
                                    <span
                                        v-html="!hasCustomFormattedColumn(col) ? getMaxColumnDesc(slotProps.data, col, getDisplayContentMaxLength(col.id)) : getFormattedColumn(slotProps.data, col)" /></a>

                                <i v-if="!HelperCommon.isNullOrWhitespace(slotProps.data[col.field]??'')" class="ml-1 pi pi-copy" style="color: rgb(0, 128, 128)"
                                    @click.stop="HelperCommon.copyToClipBoard(!hasCustomFormattedColumn(col) ? getMaxColumnDesc(slotProps.data, col, getDisplayContentMaxLength(col.id)) : getFormattedColumn(slotProps.data, col))"
                                    v-tooltip="'Copiar al portapapeles'"></i></span>
                            <div v-else>
                                <span v-if="!hasCustomFormattedColumn(col)">{{ getMaxColumnDesc(slotProps.data,
                                    col, getDisplayContentMaxLength(col.id)) }}</span>
                                <span v-else v-html="getFormattedColumn(slotProps.data, col)"> </span>
                                <Button
                                    v-if="getDisplayContentMaxLength(col.id) && (slotProps.data[(columnsToFormat.includes(col.field) ? col.field + DataSourceConst.DELIMITERFIELDFORMAT : col.field)]?.toString().length > getDisplayContentMaxLength(col.id) ?? 0)"
                                    :style="{ padding: '0.25rem 0.5rem', fontSize: '0.25rem' }" severity="secondary"
                                    icon="pi pi-ellipsis-h" text
                                    @click.stop="openColOverLayPanel($event, slotProps.data[(columnsToFormat.includes(col.field) ? col.field + DataSourceConst.DELIMITERFIELDFORMAT : col.field)])" />
                                <OverlayPanel ref="colOverLayPanel" :dismissable="false" :showCloseIcon="true">
                                    {{ dataOverLayPanel }}
                                </OverlayPanel>
                            </div>

                        </div>
                    </div>
                </template>

                <template #editor="{ data }">

                    <div v-if="isEdit(col.field) && canDoAction(ObjectGroupConst.EDIT)" style="display: flex;min-width: max-content;">                        
                        <LookUpEditor v-if="hasLookUpByFieldId(col.field)"
                            :dataSourceLookUp="(getLookUpByFieldId(col.field) ?? {})"
                            v-model="data[col.field + DataSourceConst.DELIMITERFIELDLOOKUPID]" :container="container"
                            @change:SelectedLookUpData="selectedLookUpData" 
                            @view:Details="openDetail((getLookUpByFieldId(col.field) ?? {}),data[col.field + DataSourceConst.DELIMITERFIELDLOOKUPID],data)"
                            :rowData="data"
                            :lookUpDesc="data[col.field + DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION]"
                            :catalogsData="catalogosData" :Component="Component"
                            :showSearch="showSearchLookUp(col)"
                            :isInCrud="true"
                            :filterConditions="data[col.field + DataSourceConst.DELIMITERFIELDLKFILTER]?.lookUpFilter ?? []">
                        </LookUpEditor>
                        <InputSwitch v-else-if="isColumnType(col.field, SqlTypesConst.BIT)" v-model="data[col.field]"
                            :trueValue="true" :falseValue="false" autofocus />
                        <Calendar
                            v-else-if="isColumnType(col.field, SqlTypesConst.DATE) || isColumnType(col.field, SqlTypesConst.DATETIME)"
                            v-model="data[col.field]" />
                        <InputNumber v-else-if="isColumnType(col.field, SqlTypesConst.INT)" v-model="data[col.field]"
                            autofocus />
                        <InputNumber v-else-if="isColumnType(col.field, SqlTypesConst.DECIMAL)"
                            v-model="data[col.field]" mode="currency" autofocus />
                        <Dropdown
                            v-else-if="(isColumnType(col.field, SqlTypesConst.VARCHAR) || isColumnType(col.field, SqlTypesConst.NVARCHAR)) && isCatalogTypeByFieldId(col.field)"
                            filter v-model="data[col.field]"
                            :options="getCatalogData(getCatalogTypeByFieldId(col.field))" optionLabel="description"
                            optionValue="id" />
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.TEXT)"
                            class="p-inputgroup flex-1">
                            <Textarea :id="data.value + '_value_' + data.id" rows="5" cols="30"
                                v-model="data[col.field]" />
                            <span class="p-inputgroup-addon">
                                <Mic v-model="data[col.field]"></Mic>
                            </span>
                        </div>
                        <div v-else class="p-inputgroup flex-1">
                            <InputText v-model="data[col.field]" autofocus />
                        </div>
                    </div>
                    <div v-else>
                        <div v-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.IMG)">
                            <img :src="builUrlImageData(data[col.field])" alt="logo" width="50"
                                @error="noImageUrl($event)" />
                        </div>
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.URL_IMG)">
                            <img :src="builUrlImage(data[col.field])" alt="logo" width="50"
                                @error="noImageUrl($event)" />
                        </div>
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.URL_IMG_EXT)">
                            <img :src="data[col.field]" alt="logo" width="50" @error="noImageUrl($event)" />
                        </div>
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.RPTLAUNCHERLINK)">
                            <Button icon="pi pi-file-pdf" link @click="openReportLauncher(data[col.field])"
                                size="large" />
                        </div>
                        <div v-else-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.EXTERNALLINK)">
                            <Button label="Link" link @click="openLink(data[col.field])" />
                        </div>
                        <div v-else-if="hasLookUpByFieldId(col.field)">
                            {{ data[col.field + DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION] }}
                        </div>
                        <div v-else>
                            <span v-if="hasMenuItemLinkedField(col.id)">
                                <a href="#" class=" font-bold"
                                    @click.stop="clickMenuItem(hasMenuItemLinkedField(col.id), data)">
                                    <span
                                        v-html="!hasCustomFormattedColumn(col) ? getMaxColumnDesc(data, col, getDisplayContentMaxLength(col.id)) : getFormattedColumn(data, col)" /></a>

                                <i v-if="!HelperCommon.isNullOrWhitespace(data[col.field]??'')" class="ml-1 pi pi-copy" style="color: rgb(0, 128, 128)"
                                    @click.stop="HelperCommon.copyToClipBoard(!hasCustomFormattedColumn(col) ? getMaxColumnDesc(data, col, getDisplayContentMaxLength(col.id)) : getFormattedColumn(data, col))"
                                    v-tooltip="'Copiar al portapapeles'"></i></span>
                            <div v-else>
                                <span v-if="!hasCustomFormattedColumn(col)">{{ getMaxColumnDesc(data,
                                    col, getDisplayContentMaxLength(col.id)) }}</span>
                                <span v-else v-html="getFormattedColumn(data, col)"> </span>
                                <Button
                                    v-if="getDisplayContentMaxLength(col.id) && (data[(columnsToFormat.includes(col.field) ? col.field + DataSourceConst.DELIMITERFIELDFORMAT : col.field)].toString().length > getDisplayContentMaxLength(col.id) ?? 0)"
                                    :style="{ padding: '0.25rem 0.5rem', fontSize: '0.25rem' }" severity="secondary"
                                    icon="pi pi-ellipsis-h" text
                                    @click.stop="openColOverLayPanel($event, data[(columnsToFormat.includes(col.field) ? col.field + DataSourceConst.DELIMITERFIELDFORMAT : col.field)])" />
                                <OverlayPanel ref="colOverLayPanel" :dismissable="false" :showCloseIcon="true">
                                    {{ dataOverLayPanel }}
                                </OverlayPanel>
                            </div>

                        </div>

                    </div>
                </template>
                <template v-if="hasFilterColumn(col.id)" #filter="{ filterModel, filterCallback }">
                    <Dropdown
                        v-if="filterControlType(col.id) == FilterControlTypeConst.FILTERCONTROLTYPE_DD && filterModel.matchMode != FilterMatchModeConst.IS_NOT_NULL && filterModel.matchMode != FilterMatchModeConst.IS_NULL"
                        v-model="filterModel.value"
                        @change="filterCallback()"
                        :options="getFilterOptions(col)" optionLabel="name" optionValue="id"
                        placeholder="Seleccione uno" class="p-column-filter" :showClear="true" filter
                        @before-show="colFilterBeforeShow(col)">
                        <template #option="slotProps">
                            <img v-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.IMG)"
                                :src="builUrlImage(slotProps.option.id)" alt="company logo" width="50" />
                            <span v-else v-html="slotProps.option.formattedColumn"></span><span>{{
                                slotProps.option.count }}</span>
                        </template>
                    </Dropdown>
                    <MultiSelect
                        v-else-if="filterControlType(col.id) == FilterControlTypeConst.FILTERCONTROLTYPE_MS && filterModel.matchMode != FilterMatchModeConst.IS_NOT_NULL && filterModel.matchMode != FilterMatchModeConst.IS_NULL"
                        v-model="filterModel.value"
                        @change="filterCallback()"
                        :options="getFilterOptions(col)" placeholder="Seleccione" class="p-column-filter"
                        optionLabel="name" optionValue="id" :maxSelectedLabels="1" :showClear="true" filter
                        @before-show="colFilterBeforeShow(col)">
                        <template #option="slotProps">
                            <img v-if="isColumnIdtipoCampoType(col.field, CatalogDataTypeConst.IMG)"
                                :src="builUrlImage(slotProps.option.id)" alt="company logo" width="50" />
                            <span v-else v-html="slotProps.option.formattedColumn"></span><span>{{
                                slotProps.option.count }}</span>
                        </template>
                    </MultiSelect>
                    <TriStateCheckbox
                        v-else-if="filterControlType(col.id) == FilterControlTypeConst.FILTERCONTROLTYPE_TS && filterModel.matchMode != FilterMatchModeConst.IS_NOT_NULL && filterModel.matchMode != FilterMatchModeConst.IS_NULL"
                        v-model="filterModel.value" @change="filterCallback()" />
                    <Calendar
                        v-else-if="filterControlType(col.id) == FilterControlTypeConst.FILTERCONTROLTYPE_DATE && !datesControl.includes(filterModel.matchMode)"
                        v-model="filterModel.value" dateFormat="dd/mm/yy" mask="99/99/9999" showIcon />
                    <div
                        v-else-if="filterControlType(col.id) == FilterControlTypeConst.FILTERCONTROLTYPE_IN && filterModel.matchMode != FilterMatchModeConst.IS_NOT_NULL && filterModel.matchMode != FilterMatchModeConst.IS_NULL">
                        <InputNumber v-if="isColumnType(col.field, SqlTypesConst.MONEY)" v-model="filterModel.value"
                            mode="currency" currency="EUR" locale="es-ES" />
                        <InputNumber v-else v-model="filterModel.value"
                            :mode="isColumnType(col.field, SqlTypesConst.INT) ? undefined : 'decimal'"
                            :minFractionDigits="isColumnType(col.field, SqlTypesConst.INT) ? undefined : 2"
                            :format="isColumnType(col.field, SqlTypesConst.INT) ? false : true" />
                    </div>
                    <div v-else>
                        <InputText
                            v-if="!datesControl.includes(filterModel.matchMode)"
                            v-model="filterModel.value" type="text"  class="p-column-filter"
                            placeholder="Buscar..." />
                    </div>


                </template>


                <template #filterclear="{ filterModel, filterCallback }">
                    <Button
                        label="Borrar" class="p-button p-component p-button-outlined p-button-sm"
                        @click="filterClearCallback(filterModel, filterCallback)" />
                </template>

                  <template #filterapply="{ filterModel, filterCallback }">
                    <Button
                        label="Aplicar" class="p-button p-component p-button-sm"
                        @click="filterApplyCallback(filterModel, filterCallback)" />
                </template>

               <template #filtericon>
                    <button class="p-column-filter-menu-button p-link pi pi-filter" :class="getFilterIconClass(col)"></button>
                </template>

                <template v-if="hasGroupColumns()" #footer>
                    <div class="flex">
                        <span v-html="getColumnFooter(col)"></span>
                    </div>
                </template>




            </Column>



        </DataTable>
    </div>

    <Dialog v-model:visible="op" header="Añadir registro" modal styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6"
        style="width:75%" contentStyleClass="border-round-bottom border-top-1 surface-border p-0">
        <NewRegister :container="container" :compDataSource="compDataSource" :catalogosData="catalogosData"
             :Component="Component"  @refreshData="doRefreshData" />
        <!--
            <FormBuilder v-else :container="container" :componentId="newItemFromComponentId" :applicationId="1" :applicationVersion="1" 
        @refreshData="doRefreshData"
        />
        -->
    </Dialog>

    <Dialog v-model:visible="opMassiveUpdate" header="Añadir registro" modal
        styleClass="mx-3 sm:mx-0 sm:w-full md:w-8 lg:w-6" style="width:100%"
        contentStyleClass="border-round-bottom border-top-1 surface-border p-0">
        <MasiveUpdate :container="container" :compDataSource="compDataSource" :catalogosData="catalogosData"
            :Component="Component" :selectedItems="selectedItems"  @refreshData="doRefreshData" />
    </Dialog>



    <ImportDataThrowDataSource :container="container" v-model:dialogVisible="dialogVisible" :rootParentId="Component.rootParentId"
        :Component="Component" :formKey="Component.formKey" :catalogosData="catalogosData" :compDataSource="compDataSource"
        :setupImport="setupImport" @end:import="doRefreshData(); $emit('end:import')"
        @begin:import="$emit('begin:import', $event)">
    </ImportDataThrowDataSource>

    <DocumentViewer v-if="showViewerDocument && selectedDoc" :visible="showViewerDocument"
        @update:Visibility="showViewerDocument = false; selectedDoc = null" :document="selectedDoc" :canvas="canvas" />

</template>
<script lang="ts">

import { Container } from 'inversify';
import { computed, defineComponent, onBeforeUpdate, onMounted, ref, watch } from 'vue';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
//import TableExport from '../../tabla/TableExport.vue';
import NewRegister from './NewRegister.vue'
import MasiveUpdate from './MasiveUpdate.vue';
import { useI18n } from 'vue-i18n';
//import Mic from '../   ../../mic/Mic.vue'
import Mic from '../../../../../common/infrastructure/componentes/base/common/mic/Mic.vue';
import TableExport from '../../../../../common/infrastructure/componentes/base/common/tabla/TableExport.vue';
import { messages } from './localization/MessagesBusqueda';
import { IapCatalog } from '../../../catalog/domain/iapCatalog';

import { menuPrime } from '../../../../../common/domain/modelos/menu/CustomMenuPrime';
import DataSourceComp from '../functions/dataSourceComp';
import { dataUpdate } from '../../../dataupdate/domain/dataUpdate';
import { IServiceDataUpdate } from '../../../dataupdate/application/IServiceDataUpdate';
import { TYPES } from '../../../../../common/domain/types';
import HelperLoading from '../../../../../common/infrastructure/funciones/HelperLoading';
import OperationDataTypeConst from '../../../../../common/domain/constantes/OperationDataTypeConst';
import { clave } from '../../../dataupdate/domain/clave';
import TableConst from '../../../../../common/domain/constantes/TableIcons';
import ActionTypeConst from '../../../../../common/domain/constantes/ActionTypeConst';
import SqlTypesConst from '../../../../../common/domain/constantes/SqlTypesConst';

import ImportDataThrowDataSource from '../../../datasource/infrastructure/Component/ImportDataThrowDataSource.vue';
import { IapComponentDataSource } from '../../../component/domain/iapComponentDataSource';
import { IapComponentDataSourceFieldConfiguration } from '../../../component/domain/iapComponentDataSourceFieldConfiguration';
import { LocalService, MessageService } from '../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../common/infrastructure/servicios/MessageService';
import DataSourceConst from '../functions/dataSourceConst';
import FilterControlTypeConst from '../functions/filterControlTypeConst';
import FilterCrudModeConst from '../functions/filterCrudModeConst';
import FilterMatchModeConst from '../functions/filterMatchModeConst';
import { MenuItem } from 'primevue/menuitem';
import CatalogDataTypeConst from '../../../catalog/domain/const/CatalogDataTypeConst';
import { useStore } from 'vuex';
import { IapComponent } from '../../../component/domain/iapComponent';
import HelperUtils from '../../../../../common/infrastructure/funciones/HelperUtils';
import HelperCommon from '../../../../../common/infrastructure/funciones/HelperCommon';
import LookUpSearchTable from './LookUpSearchTable.vue';
import Column from 'primevue/column';
import { DataTableCellEditInitEvent, DataTableFilterEvent, DataTablePageEvent, DataTableSelectAllChangeEvent, DataTableSortEvent } from 'primevue/datatable';
import dataSourceFieldConst from '../../../datasource/domain/const/dataSourceFieldConst';
import HelperDataUpdate from '../../../dataupdate/infrastructure/helper/HelperDataUpdate';
import { IapDataSourceField } from '../../../datasource/domain/iapDataSourceField';
import { IapDataSourceLookUp } from '../../../datasource/domain/iapDataSourceLookUp';
import CrudTableTypeConst from '../../../form/domain/Constants/CrudTableTypeConst';
import ControlTypeConst from '../../../form/domain/Constants/ControlTypeConst';
import ComponentDataForm from '../../../designer/domain/ComponentDataForm';
import ColumnTypeConst from '../../../form/domain/Constants/ColumnTypeConst';
import { IapExpression } from '../../../expression/domain/iapExpression';
import CatalogExpConst from '../../../catalog/domain/const/CatalogExpConst';
import CatalogObjectTypeConst from '../../../catalog/domain/const/CatalogObjectTypeConst';
import { ExpresionEngine } from '../../../expression/infrastructure/helper/expressionEngine';
import ExpressionNomenclatorConst from '../../../expression/domain/const/ExpressionNomenclatorConst';
import { GroupSearch, LazyParams } from '../../../search/domain/search';
import { DataUpdateOperation } from '../../../dataupdate/domain/dataUpdateOperation';
import CrudTableAggregateOperationsComp from '../functions/crudTableAggregateOperationsComp';
import CatalogTypeConst from '../../../catalog/domain/const/CatalogTypeConst';
import CatalogSortType from '../../../catalog/domain/const/CatalogSortType';
import helperCatalog from '../../../catalog/infrastructure/helper/helperCatalog';
import CatalogFnAggregateType from '../../../catalog/domain/const/CatalogFnAggregateType';
import DynamicColumn from '../../../form/infrastructure/controls/editorTemplates/prime/DynamicColumn.vue';
import HelperSecurity from '../../../../../common/infrastructure/funciones/HelperSecurity';
import ObjectGroupConst from '../../../../../common/domain/constantes/ObjectGroupConst';
import Environment from '../../../../../common/infrastructure/funciones/environment';
import { LazyDataProvider } from '../../../search/domain/lazyDataProvider';
import OperationLazyDataProviderConst from '../../../search/domain/Const/OperationLazyDataProviderConst';
import { IServiceSearch } from '../../../search/application/IServiceSearch';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import SearchDetail_Columns from './SearchDetail_Columns.vue'
import SearchDetail_Header from './SearchDetail_Header.vue'
import { DataTableSortMeta } from 'primevue/datatable';
import DataBaseTypeConst from '../../../../../common/domain/constantes/DataBaseTypeConst';
import { Dictionary } from '../../../expression/domain/dictionary';
import { IServiceDocument } from '../../../../builderDocument/document/application/IServiceDocument';
import DocumentViewer from '../../../../../common/infrastructure/componentes/base/common/visor/DocumentViewer.vue' //../../../../componentes/base/portal/documentos/DocumentViewer.vue'; 

export default defineComponent({
    name: 'search_detail',
    emits: ['update:modelValue', 'update:catalogosData', 'click:action', 'click:SelectRow', 'update:SelectMultipleRow', 'click:viewDetail', 'click:refreshData', 'click:addCustom', 'filters', 'sort', 'end:import', 'begin:import', 'selectAll', 'lazyScroll'],
    props: {
        container: {
            type: Object as () => Container
        },
     
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
        modelValue: {
            type: Object as () => [],
            default: () => ([])
        },
        filterTablas: {
            type: Object as () => string[],
            default: () => ([])
        },

        compDataSource: {
            type: Object as () => IapComponentDataSource,
            default: () => ({})
        },
        catalogosData: {
            type: Object as () => IapCatalog[],
            default: () => ([])
        },
        filasPorPagina: {
            type: Object as () => number[],
            default: () => ([5, 10, 25, 50, 100])
        },
        editAllExceptKeys: {
            type: Boolean,
            default: false
        },
        canAddNewItem: {
            type: Boolean,
            default: true
        },
        canImport: {
            type: Boolean,
            default: true
        },
        canExport: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canDeleteItem: {
            type: Boolean,
            default: false
        },
        canClearAllFilters: {
            type: Boolean,
            default: false
        },
        showActionMenu: {
            type: Boolean,
            default: false
        },
        showActionDetail: {
            type: Boolean,
            default: false
        },
        showPaginator: {
            type: Boolean,
            default: true
        },
        canUpdateMassive: {
            type: Boolean,
            default: true
        },
        canSelectMultiple: {
            type: Boolean,
            default: false
        },
        menuActionItems: {
            type: Object as () => MenuItem[],
            default: []
        },
        showSearch: {
            type: Boolean,
            default: true
        },
        showHeaderDetail: {
            type: Boolean,
            default: true
        },
        customAddNew: {
            type: Boolean,
            default: false
        },
        filterDisplay: {
            type: String,
            default: 'menu'
        },
       
        newFilters: {
            type: Object as () => any,
            default: {}
        },
        newSortData: {
            type: Object as () => any[],
            default: []
        },
        setupImport: {
            type: String,
            default: ''
        },
        lazy: {
            type: Boolean,
            default: false
        },
        totalRecords: {
            type: Number,
            default: 0
        },
        lazyScroll: {
            type: Boolean,
            default: false
        },
        lazyData: {
            type: Object as () => LazyDataProvider[],
            default: () => ([])
        },
        lazyLoading: {
            type: Boolean,
            default: false
        },
        groupSearch: {
            type: Object as () => GroupSearch,
            default: () => ([])
        },

        expandableRowGroups: {
            type: Boolean,
            default: false
        },


        rowGroupMode: {
            type: String,
            default: null
        },


        groupRowsBy: {
            type: String,
            default: null
        },


    },
    components: {
        TableExport,
        NewRegister,
        Mic,
        MasiveUpdate,
        ImportDataThrowDataSource,
        LookUpSearchTable,
        DynamicColumn,
        TriStateCheckbox,
        SearchDetail_Columns,
        SearchDetail_Header,
        DocumentViewer
    },
    setup(props, { emit }) {
        const store = useStore();
        const { t, locale } = useI18n(messages)
        const { isType, isCatalogTypeByFieldId, isCatalogType, getCatalogData, getCatalogTypeByFieldId, isColumnIdtipoCampoType, isColumnType, formatData, mustFormatData
            , tablas, tablasData, dataBaseId, hasLookUpByFieldId, getLookUpByFieldId, hasLookUpByFieldType, getDefaultvalue, getNewFiltersConditions, cleanLookUpDependency
            , cleanLookUp, resolveFieldFormatExpression, resolveDefaultValueExpression
            , getDataKeys, getDataKeyName, getDataKey, getFieldId, moneyColumns,
            mustFormatDataColuns, getFieldFromKeyCol, getFieldKeyColumn, resolveRowFormatExpression } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, props.catalogosData, store)
        const dt = ref();
        const op = ref(false);
        const dialogVisible = ref(false);
        const opMassiveUpdate = ref(false);
        const menus = ref<menuPrime[]>([]);
        const selectedColumns = ref();// ref(columns.value.slice(0, 3));
        let withHeaders = ref(false);
        const selectedItems = ref([]);
        const showHeader = ref(true);
        const lookUpData = ref();
        const dataSourceLookUpId = ref()
        const dataFooter = ref([{}])
        const filteredValue = ref([])
        const colOverLayPanel = ref();
        const dataOverLayPanel = ref();
        const user = store.getters.getCurrentUser;
        const columnsToFormat = ref(mustFormatDataColuns())
        const isUpdateFilters = ref(false);
        const first = ref(0);
        const selectAll = ref(false);
        const columnFilterLazyData = ref();
        const expandedRowGroups = ref();
        const sortConfig=ref();

        const selectedDoc = ref();
        const canvas = ref();
        const showViewerDocument = ref(false);

        
        const datesControl=[FilterMatchModeConst.IS_NULL,FilterMatchModeConst.IS_NOT_NULL,FilterMatchModeConst.CURRENT_MONTH,
        FilterMatchModeConst.CURRENT_YEAR,FilterMatchModeConst.CURRENT_WEEK,FilterMatchModeConst.PREVIOUS_MONTH,,FilterMatchModeConst.PREVIOUS_YEAR,
        FilterMatchModeConst.PREVIOUS_WEEK
        ];
        const specialFilterDatesValues=[FilterMatchModeConst.CURRENT_MONTH,FilterMatchModeConst.PREVIOUS_MONTH,FilterMatchModeConst.CURRENT_YEAR,
        FilterMatchModeConst.PREVIOUS_YEAR,FilterMatchModeConst.CURRENT_WEEK,FilterMatchModeConst.PREVIOUS_WEEK
        ];

 
        const { resolveAggregate } = CrudTableAggregateOperationsComp();
        interface dataCD {
            col: string,
            data: ComponentDataForm
        }

   

        const itemsMenuRadial = ref([
            {
                label: 'Añadir',
                icon: 'pi pi-pencil',
                command: (evt: any) => {
                    toggleNew(evt);
                },
                visible: props.canAddNewItem
            },
            {
                label: 'Importar',
                icon: 'pi pi-file-import',
                command: (evt: any) => {
                    toggleNew(evt);
                },
                visible: props.canImport
            },

            {
                label: 'Importar Masivo',
                icon: 'pi pi-save',
                command: (evt: any) => {
                    toggleNew(evt);
                },
                visible: props.canUpdateMassive && selectedItems.value.length > 0
            },


            {
                label: 'Ver',
                icon: 'pi pi-refresh',
                command: () => {
                    showHeader.value = !showHeader.value
                },
                visible: props.showHeaderDetail
            },

        ])
        const filters = ref({
            'global': { value: '', matchMode: FilterMatchMode.CONTAINS },
            'date': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
        });

        const data = computed({
            get: () => {
                
                const columng = getGroupRowsBy.value
                if (columng) {
                    //@ts-ignore:disable-next-line
                    return props.modelValue.sort((a, b) => { return (a[columng] ?? '').toLowerCase() > (b[columng] ?? '').toLowerCase() ? 1 : -1 })

                }
                else {
                    return props.modelValue
                }

            },
            set: (val) => emit('update:modelValue', val),
        });



        const catalogos = computed({
            get: () => props.catalogosData,
            set: (val) => emit('update:catalogosData', val),
        });

        const lazyScrollConfig = computed(() => ({
            lazy: true,
            onLazyLoad: handleScrollEvent,
            itemSize: 46,
            delay: 200,
            showLoader: false,
            loading: props.lazyLoading,
            numToleratedItems: 10
        }));

        const getGroupRowsBy = computed(() => {
            if (props.groupRowsBy) {

                let _cols = [] as any;

                tablas.value.forEach(x => {
                    let itemsFields = props.compDataSource.iapComponentDataSourceFieldConfigurations
                        .filter(c => c.list && c.dataSourceField?.dataSourceTableAliasId == x.id)
                        .sort((a, b) => { return (a.filterResultOrder ?? 0) - (b.filterResultOrder ?? 0); })
                        .map(t =>
                        ({
                            id: t.dataSourceField?.id,
                            header: (t.dataSourceField?.dataSourceTableAliasId === x.id) ? (t.dataSourceField?.shortDescription ?? t.dataSourceField?.field) : (t.dataSourceField?.shortDescription ?? t.dataSourceField?.fieldAlias),
                            field: (HelperCommon.isNullOrWhitespace(t.dataSourceField?.tableName ?? '') ? getFieldKeyColumn(t.dataSourceField, true) : getFieldKeyColumn(t.dataSourceField))


                        })
                        )
                    _cols = _cols.concat(itemsFields)
                })


                const _col = _cols.find(x => (x.id + '#' + props.compDataSource.id.toString()) == props.groupRowsBy)
                if (_col) {

                    return getFieldName(_col)
                }
            }
            return null;
        })

        watch(() => props.newFilters, (newValue, oldValue) => {
        
            if (newValue && Object.keys(newValue).length > 0) {
                filters.value = newValue;
            }
            else 
            {
            if(oldValue){
              //resetea filters
              buildFilters(false);
               //informa al crud
               emit('filters', filters.value);
            }
            }
        }, { immediate: true, deep: true });


         watch(() => props.newSortData, (newValue, oldValue) => {
            if (newValue && Object.keys(newValue).length > 0) {
                sortConfig.value = newValue;
            }
        }, { immediate: true, deep: true });

        const _columnsIds =
            props.compDataSource?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId == null).map(x => ({
                id: x.id,
                field: getFieldKeyColumn(x, true)
            }))
                .concat(
                    props.compDataSource?.dataSource.iapDataSourceFields.filter(x => x.dataSourceTableAliasId != null).map(x => ({
                        id: x.id,
                        field: getFieldKeyColumn(x)
                    }))

                )


        const columnsFiltered = computed(() => {
            let _cols = [] as any;

            tablas.value.forEach(x => {
                let itemsFields = props.compDataSource.iapComponentDataSourceFieldConfigurations
                    .filter(c => c.list)
                    .sort((a, b) => { return (a.filterSearchOrder ?? 0) - (b.filterSearchOrder ?? 0); })
                    .map(t =>
                        (t.dataSourceField?.dataSourceTableAliasId == null ? getFieldKeyColumn(t.dataSourceField, true) : getFieldKeyColumn(t.dataSourceField))
                    )
                _cols = _cols.concat(itemsFields)
            })

            return _cols;
        })

        const columnsFilteredList = computed(() => {

            let _cols = [] as any;
            if ((selectedColumns.value?.length ?? 0) == 0) {
                selectedColumns.value = columnsFiltered.value
            }

            
                let itemsFields = props.compDataSource.iapComponentDataSourceFieldConfigurations
                    .filter(c => c.list)
                    .sort((a, b) => { return (a.filterResultOrder ?? 0) - (b.filterResultOrder ?? 0); })
                    .map(t =>
                    ({
                        id: t.dataSourceField?.id,
                        header: (t.dataSourceField?.dataSourceTableAliasId !== null) ? (t.dataSourceField?.shortDescription ?? t.dataSourceField?.field) : (t.dataSourceField?.shortDescription ?? t.dataSourceField?.fieldAlias),
                        field: (t.dataSourceField?.dataSourceTableAliasId === null ? getFieldKeyColumn(t.dataSourceField, true) : getFieldKeyColumn(t.dataSourceField))


                    })
                    )
                _cols = _cols.concat(itemsFields)
            

            return _cols.filter(x => selectedColumns.value.includes(x.field))

        })



        const onToggle = (val: any) => {
            selectedColumns.value = val as any;
        };


        const saveData = (originalData: any, campo: any, valor: any, data: dataUpdate, transactionOperation: boolean = false) => {
            if (props.container) {

                const _srv = props.container.get<IServiceDataUpdate>(TYPES.DATAUPDATE_REPOSITORY)
                HelperLoading.showSaving()
                const requestData = JSON.parse(JSON.stringify(data))

                requestData.valores.forEach(item => {
                    item.fieldId = getFieldId(item.fieldId, tablasData.value)
                });

                const dataInputRequest: DataUpdateOperation = {
                    componentId: props.Component.id,
                    componentDataSourceId: props.compDataSource.id,
                    dataSourceId: props.compDataSource.dataSourceId,
                    parameters: props.compDataSource.dataSource.iapDataSourceServiceConfigurations ?? [],
                    data: [requestData]
                }
                _srv.update(props.Component.applicationId, props.Component.applicationVersion, [dataInputRequest], transactionOperation).then(response => {
                    if (response) {

                        if (response.length > 0) {

                            // se va a actualizar el lookupdescription
                            if (lookUpData.value) {

                                campo = campo.replace(DataSourceConst.DELIMITERFIELDLOOKUPID, '');

                                const dsLk = getFieldFromKeyCol(campo, false, tablasData.value);

                                // se actualiza la descripción
                                const _source = campo + DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION;
                                const _dest = DataSourceConst.LOOKUPDESC;
                                originalData[_source] = lookUpData.value[_dest]


                                // se actualizan el resto de campos
                                Object.keys(lookUpData.value).filter(x => x != DataSourceConst.LOOKUPDESC
                                    && !x.includes(DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS)
                                ).forEach(_field => {
                                    const newValue = lookUpData.value[_field]
                                    const dslnkFields = dsLk?.dataSourceLookUp?.dataSource.iapDataSourceFields.map(x => ({ id: x.id, field: getFieldKeyColumn(x) }));
                                    const dataSourceFieldIdSource = dslnkFields?.find((x: any) => x.field == _field)?.id;
                                    const dataSourceFieldIdTarget = dsLk?.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.find(x => x.dataSourceFieldIdSource == dataSourceFieldIdSource && x.isFieldLinked == false && x.isFieldFiltered == false)?.dataSourceFieldIdTarget;

                                    const _fieldToUpdate = tablasData.value.find(x => x.id == dataSourceFieldIdTarget)

                                    if (_fieldToUpdate) {
                                        const _fieldNew = getFieldKeyColumn(_fieldToUpdate);
                                        originalData[_fieldNew] = newValue
                                        if (columnsToFormat.value.includes(_fieldNew)) {
                                            originalData[_fieldNew + DataSourceConst.DELIMITERFIELDFORMAT] = formatData(originalData, _fieldNew)
                                        }
                                    }


                                })



                            }

                            data.valores.forEach(item => {

                                const newKey = (item.fieldId !== campo ? item.fieldId : campo);
                                const newValue = (item.fieldId !== campo ? item.value : valor);


                                originalData[newKey] = newValue;
                                if (columnsToFormat.value.includes(newKey)) {
                                    originalData[newKey + DataSourceConst.DELIMITERFIELDFORMAT] = formatData(originalData, newKey)
                                }




                                if (hasLookUpByFieldId(item.fieldId)) {
                                    originalData[newKey + DataSourceConst.DELIMITERFIELDLOOKUPID] = valor;


                                    //Actualizar los filtros de los look Ups
                                    const lookUpFilters = getNewFiltersConditions(item.fieldId, lookUpData.value);


                                    lookUpFilters.forEach(lk => {
                                        originalData[lk.fieldName + DataSourceConst.DELIMITERFIELDLKFILTER].lookUpFilter = originalData[lk.fieldName + DataSourceConst.DELIMITERFIELDLKFILTER].initialFilterlk.concat(lk.filters);
                                    });


                                    // //limpiar los filtros de las dependencias del look Up
                                    const cleanLookUps = cleanLookUpDependency([item.fieldId]);

                                    cleanLookUps.forEach(f => {
                                        originalData[f + DataSourceConst.DELIMITERFIELDLOOKUPID] = null;
                                        originalData[f + DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION] = '';
                                    })

                                    cleanLookUp.value = [];


                                }

                            })

                            lookUpData.value = null;

                        }
                        else {
                            const errors = response.filter(x => x.result == false && !HelperCommon.isNullOrWhitespace((x?.error ?? ''))).map(x => x.error);
                            if (errors.length > 0) {
                                MessageService.showMessage(MessageType.Error, "Error actualizando datos", errors.join('<br>'));

                            }
                        }
                    }
                })
                    .finally(() => {
                        HelperLoading.hideSaving()
                    })
            }
        }


        const deleteData = (data: dataUpdate) => {
            if (props.container) {
                const _srv = props.container.get<IServiceDataUpdate>(TYPES.DATAUPDATE_REPOSITORY)
                HelperLoading.showSaving()
                const requestData = JSON.parse(JSON.stringify(data))
                const dataInputRequest: DataUpdateOperation = {
                    componentId: props.Component.id,
                    componentDataSourceId: props.compDataSource.id,
                    dataSourceId: props.compDataSource.dataSourceId,
                    parameters: props.compDataSource.dataSource.iapDataSourceServiceConfigurations ?? [],
                    data: [requestData]
                }
                _srv.update(props.Component.applicationId, props.Component.applicationVersion, [dataInputRequest], false).then(response => {
                    if (HelperDataUpdate.hasErrorResponse(response)) {
                        HelperDataUpdate.formatErrorResponse(response);
                    }
                    else {
                        MessageService.showToast(MessageType.Correcto, "", 'Registro eliminado correctamente.');
                        emit('click:refreshData');
                    }

                })
                    .finally(() => {
                        HelperLoading.hideSaving()
                    })
            }
        }






        const getData = computed(() => {

            var data = [] as any;

                tablas.value.forEach(x => {
                    let itemsFields = props.compDataSource.iapComponentDataSourceFieldConfigurations
                        .filter(c => c.list && c.dataSourceField?.dataSourceTableAliasId == x.id)
                        .sort((a, b) => { return (a.filterResultOrder ?? 0) - (b.filterResultOrder ?? 0); })
                        .map(t =>
                        ({
                            label: (t.dataSourceField?.dataSourceTableAliasId === x.id) ? (t.dataSourceField?.shortDescription ?? t.dataSourceField?.field) : (t.dataSourceField?.shortDescription ?? t.dataSourceField?.fieldAlias),
                            tabla: t.dataSourceField?.tableName,
                            value: HelperCommon.isNullOrWhitespace(t.dataSourceField?.tableName ?? '') ? getFieldKeyColumn(t.dataSourceField, true) : getFieldKeyColumn(t.dataSourceField),
                            disabled: false

                        })
                        )

                        if (itemsFields.length > 0)
                        {
                            const aux = {
                                header: x,
                                label: !HelperCommon.isNullOrWhitespace(x.shortDescription) ? x.shortDescription : x.tableName,
                                icon: x.idIcon ?? 'pi pi-list',//x.icon,
                                code: x, //x.key,
                                items: itemsFields
                            }

                            data.push(aux);
                        }
                })

                // campos propios
                let itemsFieldsCustom = props.compDataSource.iapComponentDataSourceFieldConfigurations
                        .filter(c => c.list && c.dataSourceField?.dataSourceTableAliasId == null)
                        .sort((a, b) => { return (a.filterResultOrder ?? 0) - (b.filterResultOrder ?? 0); })
                        .map(t =>
                        ({
                            label: t.dataSourceField?.fieldAlias,
                            tabla:  'Calculados',
                            value: HelperCommon.isNullOrWhitespace(t.dataSourceField?.tableName ?? '') ? getFieldKeyColumn(t.dataSourceField, true) : getFieldKeyColumn(t.dataSourceField),
                            disabled: false

                        })
                        )


                        const auxCalculados = {
                        header: null,
                        label: 'Calculados',
                        icon: 'pi pi-list',//x.icon,
                        code: DataSourceConst.AGGREGATE_TABLE, //x.key,
                        items: itemsFieldsCustom
                    }

                        if (itemsFieldsCustom.length> 0)
                        {
                            data.push(auxCalculados);
                        }
                        
                        
            



            selectedColumns.value = columnsFiltered.value;
            return data;
        })


        const isEdit = (columna: string) => {            
            const item = getFieldFromKeyCol(columna, false, tablasData.value);
            if (props.editAllExceptKeys) {
                return !(item?.primaryKey ?? true)
            }
            else {
                return (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == item?.id)?.edit ?? false) && ((item?.primaryKey ?? true) == false)
            }
        }


        const isConfigFieldView = (columna: string) => {            
            const item = getFieldFromKeyCol(columna, false, tablasData.value);
            return (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(z => z.dataSourceFieldId == item?.id)?.view ?? false);
            
        }

        const toggleNew = (event: any) => {

            if (!props.customAddNew) {
                op.value = true;
            }
            else {
                // se la creación externa
                emit('click:addCustom');
            }

        }

        const toggleImport = (event: any) => {
            dialogVisible.value = true;
        }

        const toggleUpdateMassive = (event: any) => {
            opMassiveUpdate.value = true;
        }

        const onCellEditInit = (event: DataTableCellEditInitEvent) => {
            selectRow(event.data);
        }


        const onCellEditCancel = (event: any) => {
            emit('click:SelectRow', null)
        }





        const onCellEditComplete = (event: any) => {
            
            let { data, newData, field } = event;
            const _field = field.replace(DataSourceConst.DELIMITERFIELDFORMAT, '').replace(DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION, '');
            const isLookUp = hasLookUpByFieldId(_field);
            const lookUpField = (_field + DataSourceConst.DELIMITERFIELDLOOKUPID);
            let tableName = '';
            const newValue = newData[isLookUp ? lookUpField : _field]
            let dataUpdate: dataUpdate = {} as dataUpdate;
            const _forceUpdate=forceUpdate(newValue,data[isLookUp ? lookUpField : _field],newData,(isLookUp ? lookUpField : _field));

            if ((data[isLookUp ? lookUpField : _field] !== newValue) || _forceUpdate) {

                if (isLookUp) {

                    newValue.forEach((dicElement: any) => {
                        const dsf = tablasData.value.find(x => x.id == dicElement.key);
                        if (dsf) {
                            const fieldName = getFieldKeyColumn(dsf);
                            const mustSerialize = isColumnType(fieldName, SqlTypesConst.BIT);

                            //si dataUpdate esta vacío
                            if (Object.keys(dataUpdate).length === 0) {

                                dataUpdate = {
                                    valores: [{
                                        fieldId: fieldName as any,
                                        value: mustSerialize ? JSON.stringify(dicElement.value) as any : dicElement.value as any,//JSON.stringify(newValue) as any
                                    }],
                                    claves: getPrimaryKeys(data, fieldName),
                                    tipoOperacion: OperationDataTypeConst.UPDATE,
                                    id: HelperUtils.newGuid()
                                };

                            }
                            else {
                                dataUpdate.valores.push
                                    (
                                        {
                                            fieldId: fieldName as any,
                                            value: mustSerialize ? JSON.stringify(dicElement.value) as any : dicElement.value as any,//JSON.stringify(newValue) as any
                                        })

                            }
                        }


                    });


                }
                else {
                    const mustSerialize = isColumnType(_field, SqlTypesConst.BIT)
                    dataUpdate = {
                        valores: [{
                            fieldId: _field as any,
                            value: mustSerialize ? JSON.stringify(newValue) as any : newValue as any,//JSON.stringify(newValue) as any
                        }],
                        claves: getPrimaryKeys(data, _field),
                        tipoOperacion: OperationDataTypeConst.UPDATE,
                        id: HelperUtils.newGuid()
                    };
                }



                // si tenemos esto es un lookup
                if (lookUpData.value) {
                    const dsLk = getFieldFromKeyCol(_field, false, tablasData.value);

                    Object.keys(lookUpData.value).filter(x => x != DataSourceConst.LOOKUPDESC
                        && !x.includes(DataSourceConst.LOOKUPID + DataSourceConst.DELIMITERFIELDS)
                    ).forEach(_field => {
                        const dslnkFields = dsLk?.dataSourceLookUp?.dataSource.iapDataSourceFields.map(x => ({ id: x.id, field: getFieldKeyColumn(x) }));
                        const dataSourceFieldIdSource = dslnkFields?.find((x: any) => x.field == _field)?.id;
                        const dataSourceFieldIdTarget = dsLk?.dataSourceLookUp?.iapDataSourceLookUpFieldMaps.find(x => x.dataSourceFieldIdSource == dataSourceFieldIdSource && x.isFieldLinked == false && x.isFieldFiltered == false)?.dataSourceFieldIdTarget;

                        const _fieldToUpdate = tablasData.value.find(x => x.id == dataSourceFieldIdTarget)

                        const _fieldNew = getFieldKeyColumn(_fieldToUpdate);
                        const mustSerialize = isColumnType(_field, SqlTypesConst.BIT)
                        const newValue = lookUpData.value[_field]
                        if (
                            (_fieldToUpdate?.nullable == true) // columnas que admiten nulos
                            || (_fieldToUpdate?.nullable == false && !(newValue == null || newValue == undefined))) // columnas no nulas que llegan valores nulos
                        {


                            dataUpdate.valores.push
                                (
                                    {
                                        fieldId: _fieldNew as any,
                                        value: mustSerialize ? JSON.stringify(newValue) as any : newValue as any,//JSON.stringify(newValue) as any
                                    })
                        }
                    })
                    //dataUpdate.u
                }

                // se añaden los autoupdate de la configuración del componentdatasource
                props.compDataSource.iapComponentDataSourceFieldConfigurations.filter(x => x.autoUpdate)
                    .forEach(cfg => {
                        const _fieldToUpdate = tablasData.value.find(x => x.id == cfg.dataSourceFieldId && x.tableName == tableName);
                        if (_fieldToUpdate) {

                            const newField = getFieldKeyColumn(_fieldToUpdate);
                            const newFieldValue = getDefaultvalue(_fieldToUpdate, cfg.defaultValue)


                            if (
                                (_fieldToUpdate?.nullable == true) // columnas que admiten nulos
                                || (_fieldToUpdate?.nullable == false && !(newFieldValue == null || newFieldValue == undefined))) // columnas no nulas que llegan valores nulos
                            {

                                const mustSerialize = isColumnType(newField, SqlTypesConst.BIT)
                                dataUpdate.valores.push
                                    (
                                        {
                                            fieldId: newField as any,
                                            value: mustSerialize ? JSON.stringify(newFieldValue) as any : newFieldValue as any,//JSON.stringify(newValue) as any
                                        })
                            }


                        }
                    })


                const doOperationTransaction = ((Object.keys(lookUpData.value ?? {})?.length ?? 0) > 2) || dataUpdate.valores.length > 1;
                saveData(data, _field, newValue, dataUpdate, doOperationTransaction);





                //emit('saveData', {campo:field, valor:newValue, claves:getPrimaryKeys(field)});
            }

        };

        const forceUpdate = (newValue: any, oldValue: any, data: any, field: any):boolean => {
            if (!props.lazy && newValue == oldValue) {
                if (columnsToFormat.value.includes(field)) {
                    const _copyData=JSON.parse(JSON.stringify(data));
                    if (data[field + DataSourceConst.DELIMITERFIELDFORMAT] !== formatData(_copyData, field)) {
                        return true;
                    }
                }
            }
            return false;
        };

        const getPrimaryKeys = (data: any, columna: string): clave[] => {
            const _outputData: clave[] = [];
            const tableName = getFieldFromKeyCol(columna, false, tablasData.value)?.tableName

            const keys = tablasData.value.filter(x => x.tableName == tableName && x.primaryKey).map(x =>
                getFieldKeyColumn(x)
            );
            keys.forEach(k => {
                _outputData.push({
                    fieldId: getFieldId(k, tablasData.value) ?? '',
                    value: data[k]
                })
            })

            return _outputData;
        }

        const changePage = () => {
            menus.value = []

        };

        const dataTablePageEvent = (event: DataTablePageEvent) => {
            changePage();

            if (props.lazy) {
                if (selectAll.value) {
                    resolveSelectedAll();
                }
                else {
                    emit('click:refreshData', { event: event, provideData: [{ data: OperationLazyDataProviderConst.ITEMS, columnRequest: [] }] });
                }
                first.value = event.first ?? 0;
            }
        }

        const dataTableSortEvent = (event: DataTableSortEvent) => {
            changePage();
            emit('sort', event?.multiSortMeta);
            if (props.lazy) {
                emit('click:refreshData', { event: event, provideData: [{ data: OperationLazyDataProviderConst.ITEMS, columnRequest: [] }] });
            }
        }

        const dataTableFilterEvent = (event: DataTableFilterEvent) => {
            changePage();
            if (!props.lazy) {
                filteredValue.value = event.filteredValue
            }
            if (props.lazy && event && isUpdateFilters.value) {
                emit('click:refreshData', { event: event, provideData: [{ data: OperationLazyDataProviderConst.AGGREGATE, columnRequest: [] }, { data: OperationLazyDataProviderConst.COUNT, columnRequest: [] }, { data: OperationLazyDataProviderConst.ITEMS, columnRequest: [] }] });
                isUpdateFilters.value = false;
            }
        }

        const toggleMenu = (event: any, data: any) => {
            const id = getDataKey(data);
            const mnuData = menus.value.filter(x => x.id == id);
            if (mnuData.length > 0) {
                mnuData[0].menu.toggle(event);
            }
        };



        const hideMenu = (data: any) => {
            const id = getDataKey(data);
            const mnuData = menus.value.filter(x => x.id == id);
            if (mnuData.length > 0) {

                mnuData[0].menu.hide();
            }
        };


        const buildActionMenu = (data: any) => {

            //return props.menuActionItems?.filter(x => x.visible)
            const actions = props.menuActionItems.map(x => (
                {
                    key: x.key,
                    label: x.label,
                    class: x.class,
                    visible: x.visible,
                    icon: x.icon,
                    command: (event: any) => {
                        if (x?.command) {
                            selectRow(data);
                            x?.command(data);
                        }

                        hideMenu(data);
                    }
                }
            ))


            return actions.filter(x => x.visible);
        }

        const createMenuData = (id: any, el: any) => {
            if(el){
                const data = menus.value.filter(x => x.id == id);

                    if (data.length == 0) {
                        menus.value.push({ id: id, menu: el });
                    }
                }
            
        };






        const builUrlImageData = (data: string) => {
            const type = 'application/octet-stream'
            const fileURL = "data:" + type + ";base64," + (data ?? '');
            return fileURL;

        }


        const builUrlImage = (data: string) => {
            return Environment.URL_IMG_ENTITY + data;

        }

        function noImageUrl(event: any) {
            const appPublic = process.env.VUE_APP_PUBLIC_PATH ?? '/';
            let src = `${appPublic}layout/images/pages/no-image.png`;
            event.target.src = src;
        }


        const buildDataKeys = (data: any) => {
            let datakeys: clave[] = []
            tablasData.value.map(x => x.tableName).filter((value: any, index: any, self: any) => self.indexOf(value) === index).forEach(tableName => {
                const _keys = getDataKeys(data, tableName)
                _keys.forEach(_k => {
                    datakeys.push(_k)
                })
            })

            return datakeys;
        }

        const verDetalle = (data: any) => {
            emit('click:viewDetail', { keys: buildDataKeys(data), item: data })

        }


        const selectRow = (data: any) => {

            emit('click:SelectRow', { keys: buildDataKeys(data), item: data })

        }


        const clickMenuItem = (mnuItem: MenuItem | undefined, data: any) => {
            const id = getDataKey(data);
            selectRow(data);
            if (mnuItem?.command) {
                mnuItem?.command(data)
            }
        }



        const selectMultipleRow = () => {
            let data = new Array()
            if (selectedItems.value.length > 0) {
                const _dataKeys = selectedItems.value.flatMap(x => buildDataKeys(x))
                data = _dataKeys.map(x => x.value)
            }


            emit('update:SelectMultipleRow', { keys: [], items: data })

        }


        const doRefreshData = () => {
            op.value = false;
            opMassiveUpdate.value = false;
            selectedItems.value = [];
            emit('click:refreshData');
        }

        const deleteItem = (item: any) => {


            const keys = Object.keys(item)
            const _claves = getPrimaryKeys(item, keys[0])
            const description = _claves.map(x => getFieldKeyColumn(tablasData.value.find(f => f.id == x.fieldId))?.split('_')[1].toString() + ': ' + item[getFieldKeyColumn(tablasData.value.find(f => f.id == x.fieldId))]).join(',')


            var doCallbackOk = () => {

                const dataUpdate: dataUpdate =
                {
                    valores: [] as any,
                    claves: _claves,
                    tipoOperacion: OperationDataTypeConst.DELETE,
                    id: HelperUtils.newGuid()
                }

                deleteData(dataUpdate);
            }



            MessageService.showMessage(MessageType.Pregunta, '', '¿Eliminar registro "' + description + '" ?', true, true, false, '', doCallbackOk, null);

        }

        const openReportLauncher = (url: string) => {

            const cfg = store.getters.getAppConfig;
            if (cfg.length > 0) {
                const urlLaunquer = cfg.find(x => x.idKeyType == 'appconfig-template-urlreportlauncher')?.keyValue;
                window.open((urlLaunquer ?? '') + '?query=' + url, '_blank');
            }
        }

        const openLink = (url: string) => {

            window.open(url, '_blank');
        }

        const getFieldName = (col: any) => {

            if (hasLookUpByFieldId(col.field)) {

                return col.field + DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION;
            }

            return (columnsToFormat.value.includes(col.field) ? col.field + DataSourceConst.DELIMITERFIELDFORMAT : col.field)
        }

        const getFilterFieldName = (col: any) => {

            const controlType = filterControlType(col.id);

            if (controlType == FilterControlTypeConst.FILTERCONTROLTYPE_DATE || controlType == FilterControlTypeConst.FILTERCONTROLTYPE_IN || controlType == FilterControlTypeConst.FILTERCONTROLTYPE_TS) {
                return col.field;
            }

            if (hasLookUpByFieldId(col.field)) {

                return col.field + DataSourceConst.DELIMITERFIELDLOOKUPDESCRIPTION;
            }

            return (columnsToFormat.value.includes(col.field) ? col.field + DataSourceConst.DELIMITERFIELDFORMAT : col.field)
        }

        const selectedLookUpData = (data: any) => {
            lookUpData.value = data;
        }


        const hasCustomFormattedColumn = (colData: any) => {
            const expressions = props.Component.expressions?.filter((x: IapExpression) => x.idTypeExpression == CatalogExpConst.EXP_FORMAT_VALUES
                && x.idObjeto == CatalogObjectTypeConst.DSF && x.objetoId == [colData.id, props.compDataSource.id].join('#'))

            if ((expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0))?.length > 0) {
                return true;
            }
            return false;
        }

        const getMaxColumnDesc = (dataRow: any, colData: any, len: number | undefined | null) => {

            if (!dataRow) {
                return null;
            }
            const _col = (columnsToFormat.value.includes(colData?.field) ? colData?.field + DataSourceConst.DELIMITERFIELDFORMAT : colData?.field)

            if (len && dataRow[_col]) {
                return dataRow[_col]?.toString()?.length > len ? dataRow[_col]?.toString()?.substring(0, len) : dataRow[_col]?.toString();
            }
            return dataRow[_col];
        }

        const getFormattedColumn = (dataRow: any, colData: any) => {

            const expressions = props.Component.expressions?.filter((x: IapExpression) => x.idTypeExpression == CatalogExpConst.EXP_FORMAT_VALUES
                && x.idObjeto == CatalogObjectTypeConst.DSF && x.objetoId == [colData.id, props.compDataSource.id].join('#'))

            if ((expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0))?.length > 0) {
                const field = props.compDataSource.dataSource.iapDataSourceFields.find(x => x.id == colData.id)
                if (field) {
                    const localData = LocalService.getValue(props.Component.formKey + LocalService.COMPONENTS_EXP + (props.Component.rootParentId ?? -1).toString());
                    const dictionary = HelperUtils.jsonParse(localData,[])
                    Object.keys(dataRow).filter(colaName => !colaName.endsWith(DataSourceConst.DELIMITERFIELDFORMAT)).forEach(colName => {
                        const cc = _columnsIds.find(c => c.field == colName)
                        if (cc) {
                            const key = [ExpressionNomenclatorConst.EXPNOM_DSF, props.compDataSource.id, cc.id].join('.')
                            const reg = dictionary.find((x: any) => x.key == key);
                            if (reg) {
                                const _col = (columnsToFormat.value.includes(colName) ? colName + DataSourceConst.DELIMITERFIELDFORMAT : colName)
                                reg.value = dataRow[_col];
                            }
                        }
                    })


                    let resu = resolveFieldFormatExpression(field, expressions, dictionary)
                    /*if (resu) 
                    {
                        Object.keys(dataRow).filter(colaName => !colaName.endsWith(DataSourceConst.DELIMITERFIELDFORMAT)).forEach(colName => {
                            const cc = _columnsIds.find(c => c.field == colName)
                            if (cc) {
                                const key = [ExpressionNomenclatorConst.EXPNOM_DSF, props.compDataSource.id, cc.id].join('.')
                                const reg = dictionary.find((x: any) => x.key == key);
                                if (reg) {
                                    resu = resu.replaceAll(dataRow[colName], dataRow[colName + DataSourceConst.DELIMITERFIELDFORMAT])
                                }
                            }

                        })

                        
                    }*/

                    return resu
                }
            }


            return dataRow[(columnsToFormat.value.includes(colData.field) ? colData.field + DataSourceConst.DELIMITERFIELDFORMAT : colData.field)]




        }



        const hasFilterColumn = (id: string) => {
            return props.compDataSource.iapComponentDataSourceFieldConfigurations.find(x => x.dataSourceFieldId == id && !HelperCommon.isNullOrWhitespace(x.idFilterType ?? ''))
        }


        const hasMenuItemLinkedField = (id: string) => {
            const _field = id + '#' + props.compDataSource.id.toString();//.dataSource.iapDataSourceFields.find(x => x.id == id )
            const _menuItem = props.menuActionItems.find(x => x.field == _field)
            return _menuItem;
        }




        const getDisplayContentMaxLength = (id: string) => {
            return props.compDataSource.iapComponentDataSourceFieldConfigurations.find(x => x.dataSourceFieldId == id && !HelperCommon.isNullOrWhitespace(x.displayContentMaxlength?.toString() ?? ''))?.displayContentMaxlength;
        }


        const cellStyle = (id: string, all: boolean): string => {
            const propsAllCell = ['background', 'border', 'shadow', 'outline'];

            const styleCell = props.compDataSource.iapComponentDataSourceFieldConfigurations
                .find(x => x.dataSourceFieldId === id && !HelperCommon.isNullOrWhitespace(x.cellStyle ?? ''))?.cellStyle;


            if (!styleCell) {
                return '';
            }

            const arrStyles = styleCell.split(';');


            const result: string[] = arrStyles.filter(style => {

                const trimmedStyle = style.trim();


                const containsKeyword = propsAllCell.some(key => trimmedStyle.includes(key));

                return (all && containsKeyword) || (!all && !containsKeyword);
            });


            return result.join(';');
        };

        const filterControlType = (id: string) => {
            return props.compDataSource.iapComponentDataSourceFieldConfigurations.find(x => x.dataSourceFieldId == id && !HelperCommon.isNullOrWhitespace(x.idFilterControlType ?? ''))?.idFilterControlType;
        }

        const controlMode = (id: string) => {
            return props.compDataSource.iapComponentDataSourceFieldConfigurations.find(x => x.dataSourceFieldId == id && !HelperCommon.isNullOrWhitespace(x.idFilterType ?? ''))?.idFilterType;
        }

        const sortType = (col: any) => {
            const sortArr=sortConfig.value?.filter(x=>x.field==getFilterFieldName(col)) ;
            return (props.compDataSource.iapComponentDataSourceFieldConfigurations.find(x => x.dataSourceFieldId == col?.id && !HelperCommon.isNullOrWhitespace(x.idSortType ?? ''))?.idSortType)
            || sortArr?.length>0;
        }

        const isColumnFilterOption = (id: string): boolean => {

            let filterableColumn: string[] = [FilterControlTypeConst.FILTERCONTROLTYPE_IT];
            const controlType = filterControlType(id);
            const ctrlMode = controlMode(id);

            if (controlType && filterableColumn.includes(controlType)) {
                return true;
            }
            if (ctrlMode && !controlType) {
                return true;
            }

            return false;
        }

        const showFilterMatchMode = (id: string) => {

            const controlType = filterControlType(id);

            if (controlType == FilterControlTypeConst.FILTERCONTROLTYPE_MS || controlType == FilterControlTypeConst.FILTERCONTROLTYPE_DD || controlType==FilterControlTypeConst.FILTERCONTROLTYPE_TS) {
                return false;
            }
            return true;
        }

        const dataTypeFilterControl = (id: string) => {

            const controlType = filterControlType(id);

            switch (controlType) {
                case FilterControlTypeConst.FILTERCONTROLTYPE_IN:
                    return 'numeric';
                    break;
                case FilterControlTypeConst.FILTERCONTROLTYPE_DATE:
                    return 'date';
                    break;
                default:
                    return undefined;
                    break;
            }
        }

      


        const hasGroupColumns = () => {
            return props.compDataSource.iapComponentDataSourceFieldConfigurations.find(x => !HelperCommon.isNullOrWhitespace(x.aggregateOperation ?? ''))
        }

        const getColumnFooter = (col: any) => {

            const output = new Array<string>();
            const columnCfg = props.compDataSource.iapComponentDataSourceFieldConfigurations.find(x => x.dataSourceFieldId == col.id)?.aggregateOperation ?? ''
            if (!HelperCommon.isNullOrWhitespace(columnCfg)) {
                if (!props.lazy) {
                    columnCfg?.split(',').forEach(idType => {

                        if (!HelperCommon.isNullOrWhitespace(idType)) {
                            const cat = helperCatalog.getCatalog(CatalogTypeConst.FNAGGREGATE).find(x => x.id == idType)
                            const resuOp = resolveAggregate(idType, filteredValue.value, col.field)
                            const obj = {}
                            obj[col.field] = resuOp
                            output.push((cat?.value ?? '') + ': <b>' + (cat?.id == CatalogFnAggregateType.COUNT ? resuOp : formatData(obj, col.field, true, true, true)) + '</b>')
                        }
                    })
                }
                else {

                    const lazyDataAggr = props.lazyData.find(x => x.operation == OperationLazyDataProviderConst.AGGREGATE);
                    const items = JSON.parse(lazyDataAggr?.items ?? '[]');


                    if (items.length > 0) {
                        Object.keys(items[0]).forEach(aggr => {
                            const columnAlias = aggr.split('_');
                            if (columnAlias.length > 0) {
                                if (columnAlias[0] == col.id.replaceAll('-', '')) {
                                    const cat = helperCatalog.getCatalog(CatalogTypeConst.FNAGGREGATE).find(x => x.id == columnAlias[2])
                                    const obj = {}
                                    obj[col.field] = items[0][aggr] ?? 0
                                    output.push((cat?.value ?? '') + ': <b>' + ((columnAlias[2] == CatalogFnAggregateType.COUNT ? items[0][aggr] ?? 0 : formatData(obj, col.field, true, true, true))) + '</b>');
                                }
                            }
                        });
                    }



                }
            }

            return output.join('</br>');
        }

        const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
            arr.reduce((groups, item) => {
                (groups[key(item)] ||= []).push(item);
                return groups;
            }, {} as Record<K, T[]>);


        const getFilterOptions = (col: any): any[] => {

            if (!props.lazy) {
                const groupDataAll = groupBy(data.value, x => x[(columnsToFormat.value.includes(col.field) ? col.field + DataSourceConst.DELIMITERFIELDFORMAT : col.field)])
                const groupDataFiltered = groupBy(props.lazy ? data.value : filteredValue.value, x => x[(columnsToFormat.value.includes(col.field) ? col.field + DataSourceConst.DELIMITERFIELDFORMAT : col.field)])

                return Object.keys(groupDataAll).map(x => ({
                    id: x,
                    count: ' (' + getCount(col, x, groupDataFiltered) + ')',
                    name: x + ' (' + getCount(col, x, groupDataFiltered) + ')',
                    formattedColumn: getFormattedColumn(groupDataAll[x][0], col)
                }))
            }
            else {
                const lazyColumnData = columnFilterLazyData?.value?.filter(x => x.operation == OperationLazyDataProviderConst.CONTROL_COLUMN_FILTER_DATA && x.fieldId == col.id);
                if (columnFilterLazyData.value && lazyColumnData && lazyColumnData.length > 0) {
                    const items = JSON.parse(lazyColumnData[0]?.items);
                    const _optItems = items.map((x: any) => ({ id: x[col.field], count: ' (' + x.count + ')', name: formatData(x, col.field) + ' (' + x.count + ')', formattedColumn: formatData(x, col.field) }));
                    //si el id de las opciones esta en el catalogo ordena en base al order
                    return _optItems.sort((a, b) => { return (props.catalogosData.find(z => z.id == a.id)?.order ?? 0) - (props.catalogosData.find(z => z.id == b.id)?.order ?? 0); });

                }
                return [];


            }


        }


        const getCount = (col: any, key: string, groupDataFiltered: any): string => {

            if (key in groupDataFiltered) {
                return groupDataFiltered[key]?.length?.toString();
            }

            return '0';
        }


        const getLazyDataControl = (colId) => {

            if (props.container) {

                const { adjustCdsAndClauses, clearEmptyFiltersList, adjustAllTreeSearchRequest, reorderSearchTree, filterCatalogsFields } = DataSourceComp(props.container as any, props, emit, t, props.compDataSource, props.catalogosData, store)

                let data = JSON.parse(JSON.stringify(props.groupSearch));

                //sacar los searchData del primer nivel que tienen filtros de expresiones y no son campos de búsqueda por defecto, estos son AND estrictos. Seria los campos ocultos de busqueda.
                reorderSearchTree(data);

                clearEmptyFiltersList(data)

                adjustAllTreeSearchRequest(data);

                const cdsCopy = adjustCdsAndClauses(props.compDataSource);


                let lazyParams: LazyParams = {} as any;

                if (props.lazy) {
                    lazyParams = {
                        first: 0,
                        rows: 10,
                        filters: JSON.stringify(filters.value),
                        multiSortMeta: [],
                        fieldsConfig: props.compDataSource?.iapComponentDataSourceFieldConfigurations,
                        catalogs: [],
                        provideData: [{ data: OperationLazyDataProviderConst.CONTROL_COLUMN_FILTER_DATA, columnRequest: [colId] }]
                    }
                }

                const _srv = props.container.get<IServiceSearch>(
                    TYPES.SEARCH_REPOSITORY
                );


                _srv.search(props.Component.applicationId, props.Component.applicationVersion, props.Component.id,
                    data as any, [], cdsCopy?.maxRowsReturned ?? 100, cdsCopy, cdsCopy?.dataSourceId, false, lazyParams)
                    .then((response) => {

                        columnFilterLazyData.value = response.lazyData;



                    })
                    .finally(() => {
                    });

            }

        }


        const buildFilters = (defaultValue: boolean = true) => {
            _columnsIds.forEach(c => {

                const fieldConfig = props.compDataSource?.iapComponentDataSourceFieldConfigurations.find(cfg => cfg.dataSourceFieldId == c.id);
                filters.value[getFilterFieldName(c)] = resolveFilter(fieldConfig, defaultValue);

            })

        }

        const buildSort=()=>{
        
         let sortArr:DataTableSortMeta[]=[];

         _columnsIds.forEach(c => {
                const fieldConfigSort = props.compDataSource?.iapComponentDataSourceFieldConfigurations?.find(cfg => cfg.dataSourceFieldId == c.id && !HelperCommon.isNullOrWhitespace(cfg.idSortType ?? ''))?.idSortType;
                if(fieldConfigSort  && fieldConfigSort!==CatalogSortType.NOT_ORDENATION){
                    sortArr.push({field:getFilterFieldName(c),order:fieldConfigSort==CatalogSortType.ASC?1:-1});   
                }

            })
                
           sortConfig.value=sortArr.sort((a, b) => { return (props.compDataSource?.iapComponentDataSourceFieldConfigurations.find(z =>getFilterFieldName({id:z.dataSourceFieldId,header:'',field:getFieldKeyColumn(z.dataSourceField)}) == a.field)?.sortOrder ?? 0) - (props.compDataSource?.iapComponentDataSourceFieldConfigurations.find(z => getFilterFieldName({id:z.dataSourceFieldId,header:'',field:getFieldKeyColumn(z.dataSourceField)}) == b.field)?.sortOrder ?? 0); });    
           
            emit('sort',sortConfig.value);
        }

        const resolveFilter = (config: IapComponentDataSourceFieldConfiguration | undefined, defaultValue: boolean = true) => {

            let filterResolved={};            
            if (config) {
                switch (config.idFilterControlType) {
                    case FilterControlTypeConst.FILTERCONTROLTYPE_IT:
                    case FilterControlTypeConst.FILTERCONTROLTYPE_DATE:
                    case FilterControlTypeConst.FILTERCONTROLTYPE_IN:
                        if (config.idFilterType == FilterCrudModeConst.FILTERCRUD_ADV) {
                           filterResolved= { operator: FilterOperator.AND, constraints: [{ value: defaultValue ? resolveDefaultValue(config) : null, matchMode: resolveMatchMode(config,defaultValue) }] }
                        }
                        else {
                           filterResolved= { value: defaultValue ? resolveDefaultValue(config) : null, matchMode: resolveMatchMode(config,defaultValue) }
                        }

                        break;

                    default:
                        filterResolved= { value: defaultValue ? resolveDefaultValue(config) : null, matchMode: resolveMatchMode(config,defaultValue) }
                        break;
                }

                setSpecialDatesValues(filterResolved);

                return filterResolved;
            }
        }

        const resolveMatchMode = (config: IapComponentDataSourceFieldConfiguration | undefined,defaultValue: boolean = true) => {
            if (config?.idDefaultMatchMode) {
              const catalogValue=props.catalogosData.find(x=>x.id==config?.idDefaultMatchMode);

              if(catalogValue){
                  return catalogValue.value;
              }
                
            }
            if(config?.idFilterControlType){
                return getFilterMatchMode(config.idFilterControlType)
            }

             return FilterMatchModeConst.CONTAINS;
        }

        const resolveDefaultValue = (config: IapComponentDataSourceFieldConfiguration | undefined) => {

            if (config) {
                if (config.dataSourceField) {
                    const resu = resolveDefaultValueExpression(config?.dataSourceField);

                    if (resu) {
                        return convertDefaultValue(config, resu);
                    }
                }

                if (config.defaultFilterValue) {
                    return convertDefaultValue(config, config.defaultFilterValue);
                }
            }

            return null;
        }

        const convertDefaultValue = (config: IapComponentDataSourceFieldConfiguration, value: string) => {

            if (config && config.idFilterControlType) {
                switch (config.idFilterControlType) {
                    case FilterControlTypeConst.FILTERCONTROLTYPE_CB:
                        return value.toLowerCase() === 'true' ? true : false;
                        break;
                    case FilterControlTypeConst.FILTERCONTROLTYPE_MS:
                        try {
                            const convertedArr: string[] = JSON.parse(value);
                            return convertedArr;
                        }
                        catch (error) {
                            return null;
                        }
                        break;
                    default:
                        return value;
                        break;
                }
            }
        }

        const getFilterMatchMode = (filterControlType: string): any => {
            switch (filterControlType) {
                case FilterControlTypeConst.FILTERCONTROLTYPE_CB:
                case FilterControlTypeConst.FILTERCONTROLTYPE_TS:
                case FilterControlTypeConst.FILTERCONTROLTYPE_DD:
                case FilterControlTypeConst.FILTERCONTROLTYPE_IN:
                    return FilterMatchModeConst.EQUALS;
                    break;
                case FilterControlTypeConst.FILTERCONTROLTYPE_MS:
                    return FilterMatchModeConst.IN;
                    break;
                case FilterControlTypeConst.FILTERCONTROLTYPE_DATE:
                    return FilterMatchModeConst.DATE_IS;
                    break;
                default:
                    return FilterMatchModeConst.CONTAINS;
                    break;
            }
        }

        const onFilterUpdate = (event: any) => {
            isUpdateFilters.value = true;

            Object.keys(event).forEach(key => {

                if ((event[key].hasOwnProperty('operator') && event[key].operator == null) || (!event[key].hasOwnProperty('operator') && event[key]?.matchMode == null) ) {

                    _columnsIds.forEach(c => {
                        if (key.replace(DataSourceConst.DELIMITERFIELDFORMAT, '') == c.field) {
                            const fieldConfig = props.compDataSource?.iapComponentDataSourceFieldConfigurations.find(cfg => cfg.dataSourceFieldId == c.id);
                            filters.value[getFilterFieldName(c)] = resolveFilter(fieldConfig, false);
                            
                        }
                    })
                }

            });

            emit('filters', filters.value);

        };

        const globalFilterSearch = () => {
            if (props.lazy) {
                emit('click:refreshData');
            }
        };

        const clearFilter = () => {
            buildFilters(false);
            if (props.lazy) {
                emit('click:refreshData', { event: undefined, provideData: [{ data: OperationLazyDataProviderConst.AGGREGATE, columnRequest: [] }, { data: OperationLazyDataProviderConst.COUNT, columnRequest: [] }, { data: OperationLazyDataProviderConst.ITEMS, columnRequest: [] }] });
            }
        };


        const canDoAction = (action: string) => {
            return HelperSecurity.canDoOperation(user, props.Component.objectGroups, action)
        }

        const openColOverLayPanel = (event: any, data: any) => {
            colOverLayPanel.value[0].toggle(event);
            dataOverLayPanel.value = data;

        }

        const resolveSelectedAll = (selectItems: boolean = true) => {
            //se buscan todos los registros
            emit('selectAll');
            if (selectItems) {
                setTimeout(() => {
                    //se asignan todos los registros a los items seleccionados
                    selectedItems.value = data.value;

                }, 1000);
            }

        }


        const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {

            selectAll.value = event.checked;

            if (props.lazy) {
                if (selectAll.value) {
                    resolveSelectedAll();
                }
                else {
                    selectAll.value = false;
                    selectedItems.value = [];
                }
            }
            else {
                if (selectAll.value) {
                    selectedItems.value = data.value;
                }
                else {
                    selectAll.value = false;
                    selectedItems.value = [];
                }

            }
        }

        const cleanGlobalSearch = () => {
            filters.value['global'].value = '';
            globalFilterSearch();
        }

        const handleScrollEvent = (event: any) => {
            emit('lazyScroll', { event: event, provideData: [{ data: OperationLazyDataProviderConst.AGGREGATE, columnRequest: [] }, { data: OperationLazyDataProviderConst.COUNT, columnRequest: [] }, { data: OperationLazyDataProviderConst.ITEMS, columnRequest: [] }] });
        }

        const colFilterBeforeShow = (col: any) => {
            if (props.lazy) {
                getLazyDataControl(col.id);
            }
        }

        const rowClass = (data: any) => {
            const expResu = getFormattedRow(data, CrudTableTypeConst.ROWCLASS);
            return resolveFormattedRow(expResu, data);
        };

        const rowStyle = (data: any) => {
            const expResu = getFormattedRow(data, CrudTableTypeConst.ROWSTYLE);
            return resolveFormattedRow(expResu, data);
        };

        const resolveFormattedRow = (expResu: any, dataRow: any) => {
            const splitResu = expResu?.split('#');
            if (splitResu && splitResu.length > 1) {
                const checked: boolean = (splitResu[0].toLowerCase() == 'true');
                if (checked) {
                    return isRowChecked(dataRow) ? splitResu[1] : '';
                }
                else {
                    return splitResu[1];
                }
            }
            else {
                return expResu;
            }

        }

        const isRowChecked = (dataRow: any) => {
            let rowChecked: boolean = false;
            selectedItems.value.forEach(x => {
                const deepEqual = require('deep-equal');
                if (deepEqual(x, dataRow)) {
                    rowChecked = true;
                }
            });

            return rowChecked;
        }


        const getFormattedRow = (dataRow: any, attrName: string) => {

            const attrId = props.Component.iapComponentAttributes.find(x => x.name == attrName)?.id;
            const expressions = props.Component.expressions?.filter((x: IapExpression) => x.idTypeExpression == CatalogExpConst.EXP_FORMAT_VALUES
                && x.idObjeto == CatalogObjectTypeConst.ATTRCOMP && x.objetoId == attrId);

            if ((expressions?.filter((x: IapExpression) => x.iapExpressionDetails?.length > 0))?.length > 0) {

                const localData = LocalService.getValue(props.Component.formKey + LocalService.COMPONENTS_EXP + (props.Component.rootParentId ?? -1).toString());
                const dictionary = HelperUtils.jsonParse(localData,[])

                Object.keys(dataRow).filter(colaName => !colaName.endsWith(DataSourceConst.DELIMITERFIELDFORMAT)).forEach(colName => {
                    const cc = _columnsIds.find(c => c.field == colName)
                    if (cc) {
                        const key = [ExpressionNomenclatorConst.EXPNOM_DSF, props.compDataSource.id, cc.id].join('.')
                        const reg = dictionary.find((x: any) => x.key == key);
                        if (reg) {
                            const _col = (columnsToFormat.value.includes(colName) ? colName + DataSourceConst.DELIMITERFIELDFORMAT : colName)
                            reg.value = dataRow[_col];
                        }
                    }
                })


                let resu = resolveRowFormatExpression(attrId ?? '', expressions, dictionary)

                return resu ?? '';

            }


        }


        const filterClearCallback = (filterModel: any, filterCallBack: any) => {
            if ('constraints' in filterModel) {
                filterModel.constraints.forEach(x => {
                    if (!HelperCommon.isNullOrWhitespace(x?.value)) {
                        x.value = null;
                    }

                })
            }
            else {
                filterModel.value = null;
            }

            filterCallBack();
        }


        const filterApplyCallback = (filterModel: any, filterCallBack: any) => {

            setSpecialDatesValues(filterModel);
            filterCallBack();
        }

        const setSpecialDatesValues=(filterModel:any)=>{
        
           if ('constraints' in filterModel) {
                filterModel.constraints.forEach(x=>{
                        if(specialFilterDatesValues.includes(x.matchMode)){
                               x['rangeDateTime']=getSpecialFilterDatesValues(x.matchMode);
                        }
                });
             }
             else{
               if(specialFilterDatesValues.includes(filterModel.matchMode)){
                             filterModel['rangeDateTime']==getSpecialFilterDatesValues(filterModel.matchMode);
                }
                
             }
  

        }

     
        const getSpecialFilterDatesValues=(matchMode:string): string=>{
             
                let dateFrom: Date | null = null;
                let dateTo: Date | null = null;

                if (matchMode == FilterMatchModeConst.CURRENT_MONTH){
                    const currMonthDates=HelperCommon.currentMonthDates();
                    dateFrom=currMonthDates.first;
                    dateTo=currMonthDates.last;
                }
                if (matchMode== FilterMatchModeConst.PREVIOUS_MONTH){

                    const prevMonthDates=HelperCommon.previousMonthDates();
                    dateFrom=prevMonthDates.first;
                    dateTo=prevMonthDates.last;
                }

                if (matchMode == FilterMatchModeConst.CURRENT_YEAR){

                    const currYearDates=HelperCommon.currentYearDates();
                    dateFrom=currYearDates.first;
                    dateTo=currYearDates.last;
                }

                if (matchMode == FilterMatchModeConst.PREVIOUS_YEAR){
                    const prevYearDates=HelperCommon.previousYearDates();
                    dateFrom=prevYearDates.first;
                    dateTo=prevYearDates.last;
                }

                if (matchMode == FilterMatchModeConst.CURRENT_WEEK){
                    const currWeekDates=HelperCommon.currentWeekDates();
                    dateFrom=currWeekDates.first;
                    dateTo=currWeekDates.last;
                }

                if (matchMode == FilterMatchModeConst.PREVIOUS_WEEK){
                    const prevWeekDates=HelperCommon.previousWeekDates();
                    dateFrom=prevWeekDates.first;
                    dateTo=prevWeekDates.last;
                }

                const dateRange = { From: dateFrom, To: dateTo };
                return JSON.stringify(dateRange);
              
        }

        const getFilterIconClass= computed(() => (col:any) => {
                        
            const filter = filters.value[getFilterFieldName(col)];
            let classToApply:string="";
            
            if(filter){
                        if ('constraints' in filter) {
                            filter.constraints.forEach(x => {
                                   if(applyFilterIconClass(x)){
                                      classToApply= 'p-column-filter-menu-button-active';
                                   }
                            });
                        }
                        else {
                            if(applyFilterIconClass(filter)){
                                     classToApply= 'p-column-filter-menu-button-active';
                             }
                        }

                        }

            return classToApply;
         });

         const applyFilterIconClass=(filterModel:any)=>{
             return (filterModel.value!=null || (specialFilterDatesValues.includes(filterModel.matchMode) && filterModel?.rangeDateTime && filterModel?.rangeDateTime!=null) || filterModel.matchMode==FilterMatchModeConst.IS_NULL || filterModel.matchMode==FilterMatchModeConst.IS_NOT_NULL);           
         }


         const openDetail = (dsLookUp:IapDataSourceLookUp,dsLkModel:any,data:any) =>{

            if (props.container && dsLkModel?.length > 0) {
                    const docId = dsLkModel[0]['value']
                    const cfg = JSON.parse(dsLookUp?.parameters ?? '[]') as Dictionary<string,string>[]
                    const source = cfg.find(x=> x.value == 'documentConnection')?.key ?? DataBaseTypeConst.DOCUMENTOS_MODEL

                    HelperLoading.showLoading();
                    const _srv = props.container.get<IServiceDocument>(TYPES.STORAGE_REPOSITORY)
                    _srv.getById(docId, props.Component.id as any, source,true).then(response => {

                        if (response) {
                            selectedDoc.value = response;
                            showViewerDocument.value = true;
                        }
                    }).finally(() => {
                        HelperLoading.hideLoading();
                    })
                }          

         }

         const showSearchLookUp = (col:any) => {
              const lk=getLookUpByFieldId(col.field);
              return ((lk?.idControlType==ControlTypeConst.CTDOCUMENT || lk?.idControlType==ControlTypeConst.CTSEARCH) && !isConfigFieldView(col.field));
         };

        onMounted(() => {
            showHeader.value = props.showHeaderDetail;
            selectedColumns.value = columnsFiltered.value
            buildFilters();
            buildSort();
            emit('filters', filters.value);

            if (props.lazy && props.compDataSource.autoLoad) {
                emit('click:refreshData', undefined);
            }

            if (props.canSelectMultiple) {
                watch(selectedItems, () => {
                    selectMultipleRow()
                },
                    {
                        flush: 'post',
                        //immediate: true, 
                        deep: false
                    }
                )

            }


        });

        onBeforeUpdate(() => {
            menus.value = []
        });

        return {
            op,
            dialogVisible,
            t,
            dt,
            filters,
            data,
            //columns,
            selectedColumns,
            DataSourceConst,
            isCatalogTypeByFieldId,
            onToggle,
            formatData,
            getData,
            isEdit,
            onCellEditComplete,
            onCellEditInit,
            onCellEditCancel,
            isColumnType,
            isColumnIdtipoCampoType,
            isCatalogType,
            SqlTypesConst,
            toggleNew,
            toggleImport,
            columnsFiltered,
            columnsFilteredList,
            changePage,
            dataTablePageEvent,
            dataTableSortEvent,
            dataTableFilterEvent,
            buildActionMenu,
            toggleMenu,
            createMenuData,
            getDataKey,
            getCatalogData,
            getCatalogTypeByFieldId,
            CatalogDataTypeConst,
            builUrlImage,
            verDetalle
            , tablas
            , tablasData
            , dataBaseId
            , doRefreshData
            , deleteItem,
            withHeaders,
            toggleUpdateMassive,
            opMassiveUpdate,
            selectedItems,
            getDataKeyName,
            openReportLauncher,
            openLink,
            showHeader,
            getFieldName,
            hasLookUpByFieldId,
            getLookUpByFieldId,
            selectedLookUpData,
            hasLookUpByFieldType,
            itemsMenuRadial,
            ColumnTypeConst,
            getFormattedColumn,
            hasGroupColumns,
            getColumnFooter,
            dataFooter,
            filteredValue,
            getFilterOptions,
            hasFilterColumn,
            filterControlType,
            FilterControlTypeConst,
            isColumnFilterOption,
            dataTypeFilterControl,
            showFilterMatchMode,
            getFilterFieldName,
            onFilterUpdate,
            clearFilter,
            canDoAction,
            ObjectGroupConst,
            hasCustomFormattedColumn,
            getMaxColumnDesc,
            colOverLayPanel,
            openColOverLayPanel,
            dataOverLayPanel,
            getDisplayContentMaxLength,
            builUrlImageData,
            noImageUrl,
            columnsToFormat,
            sortType,
            first,

            globalFilterSearch,
            onSelectAllChange,
            selectAll,
            resolveSelectedAll,
            cleanGlobalSearch,
            lazyScrollConfig,
            colFilterBeforeShow,
            cellStyle,
            hasMenuItemLinkedField,
            HelperCommon,
            clickMenuItem,
            rowClass,
            rowStyle,
            moneyColumns,
            FilterMatchModeConst,
            expandedRowGroups,
            getGroupRowsBy,
            filterClearCallback,
            filterApplyCallback,
            sortConfig,
            datesControl,
            getFilterIconClass,
            openDetail,
            selectedDoc,
            showViewerDocument,
            canvas,
            ControlTypeConst,
            isConfigFieldView,
            showSearchLookUp

        };
    },
});
</script>
<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
    padding-top: 0;
    padding-bottom: 0;
}

::v-deep(.p-datatable .p-datatable-tbody > tr > td) {
    padding: 0.5rem 0.5rem !important;
}

::v-deep(.p-datatable table) {
     border-collapse: separate !important;
}

</style>
