<template>
    <SummaryDebug v-if="loaded && getPropertyBooleanValue(BaseControlTypeConst.DEBUG,Component)"
            :data="Component"
            :vmodel="vmodel"
            />
    
    <div class="card">
        <div class="flex flex-column md:flex-row gap-4">
            <div class="w-full md:w-3 xl:w-2 xl:p-3">
                <MailSidebar :items="sidebarItems" :activeMailItem="activeMailItem" @navigate="handleNavigate"></MailSidebar>
            </div>
            <div class="md:w-9 xl:w-10 xl:p-3">
                <div class="w-full">
                    <MailTypes v-if="sections.inbox" :mails="allMails" :totalMails="mailsCount" @navigate="handleNavigate" @pageChange="getMails" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailTypes>
                    <MailTypes v-if="sections.draft" :mails="allDrafts" :totalMails="draftsCount" @navigate="handleNavigate" @pageChange="getDrafts" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailTypes>
                    <MailTypes v-if="sections.trash" :mails="allTrash" :totalMails="trashCount" @navigate="handleNavigate" @pageChange="getTrash" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailTypes>
                    <MailTypes v-if="sections.archived" :mails="allArchived" :totalMails="archivedCount" @navigate="handleNavigate" @pageChange="getArchived" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailTypes>
                    <MailTypes v-if="sections.sent" :mails="allSent" :totalMails="sentCount" @navigate="handleNavigate" @pageChange="getSent" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailTypes>
                    <MailTypes v-if="sections.spam" :mails="allSpam" :totalMails="spamCount" @navigate="handleNavigate" @pageChange="getSpam" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailTypes>
                    <MailTypes v-if="sections.starred" :mails="allStarred" :totalMails="starredCount" @navigate="handleNavigate" @pageChange="getStarred" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailTypes>
                    <MailTypes v-if="sections.important" :mails="allImportant" :totalMails="importantCount" @navigate="handleNavigate" @pageChange="getImportants" @archive="moveToArchive" @spam="moveToSpam"></MailTypes>
                    <MailDetail v-if="sections.detail" :mails="getDetailMails()" :emailId="emailId" @navigate="handleNavigate" @trash="moveToTrash" @archive="moveToArchive" @spam="moveToSpam"></MailDetail>
                    <DynamicEmail v-if="sections.compose" :paramEmail="emailId != null ? allDrafts.find(d => d.id == emailId): undefined" :container="container" :Component="Component" :slotProps="slotProps" @navigate="handleNavigate"></DynamicEmail>
                </div>
            </div>
        </div>
    </div>
    <Reply v-model:visible="dialogVisible" :mail-detail="mailDetail" @save="onSaveReplyMail" @update:dialogVisible="onChangeDialogVisibility()"></Reply>
</template>
<script lang="ts">


import { defineComponent, onMounted, ref, shallowRef, onBeforeUnmount, watch, watchEffect, computed, onUnmounted } from 'vue';
import { MessageService } from '../../../../../../../common/infrastructure/servicios';
import { MessageType } from '../../../../../../../common/infrastructure/servicios/MessageService';
import ComponentCommonRender from '../../../../domain/Functions/ComponentCommonRender';
import { Container } from 'inversify';
import BaseControlTypeConst from '../../../../domain/Constants/BaseControlTypeConst';
import ComponentDataForm from '../../../../../designer/domain/ComponentDataForm';


import { useStore } from 'vuex';
import ObjectGroupConst from '../../../../../../../common/domain/constantes/ObjectGroupConst';
import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import SummaryDebug from '../../shared/SummaryDebug.vue';
import Editor from 'primevue/editor';
import EmailTypeConst from '../../../../domain/Constants/EmailTypeConst';
import ControlTypeConst from '../../../../domain/Constants/ControlTypeConst';
import CrudDocument from '../../../../../../builderDocument/document/infrastructure/component/CrudDocument.vue'
import { StorageDocument } from '../../../../../../builderDocument/document/domain/storageDocument';
import { FileUploadRemoveEvent } from 'primevue/fileupload';
import { uploadFile } from '../../../../../../builderMaster/auth/domain/uploadFile';
import HelperCommon from '../../../../../../../common/infrastructure/funciones/HelperCommon';
import { Actions } from '../../../../../../../common/infrastructure/almacen/enums/StoreEnums';
import HelperLoading from '../../../../../../../common/infrastructure/funciones/HelperLoading';
import { msalInstance, initializeMsalInstance, authMicrosoft, getAccessToken } from "../../../../../../builderMaster/auth/infrastructure/component/authConfig";
import { useRoute } from 'vue-router';
import { useToast } from 'primevue/usetoast';
import MailSidebar from './DynamicEmailSideBar.vue';
import MailTypes from './DynamicEmailTypes.vue';
import MailDetail from './DynamicEmailDetail.vue';
import Reply from './DynamicEmailReply.vue';
import DynamicEmail from './DynamicEmail.vue';

export default defineComponent({
    name: 'dynamic_crud_tabla',
    components: {
        SummaryDebug,
        Editor,
        CrudDocument,
        MailSidebar,
        MailTypes,
        MailDetail,
        Reply,
        DynamicEmail
    },
    props:
    {
        container: {
            type: Object as () => Container
        },
        Component: {
            type: Object as () => ComponentDataForm,
            default: () => ({})
        },
       
        slotProps: {
            type: Object,
            default: () => ({})
        },
        formKey: {
            type: String,
            default: ''
        },

    },
    setup(props, context) {
        //
        const store = useStore();
        const { vmodel, canDoOperation, getProperty, getPropertyValue, setPropertyValueByName, 
            getPropertyBooleanValue, getPropertyNumberValue, getLayoutComponent, loaded, processEventComponent
            ,componentKey,baseOnUnmount
        } = ComponentCommonRender(props.Component,props.slotProps, props.container,store);
        
        
        const toast = useToast();
        const emailId = ref(null);
        const allMails = ref([]) as any;
        const allDrafts = ref([]) as any;
        const allTrash = ref([]) as any;
        const allArchived = ref([]) as any;
        const allStarred = ref([]) as any;
        const allImportant = ref([]) as any;
        const allSpam = ref([]) as any;
        const allSent = ref([]) as any;
        const filteredMails = ref({});
        const activeMailItem = ref('inbox');
        const fromActiveMailItem = ref('');
        const dialogVisible = ref(false);
        const mailDetail = ref(null);
        const mailsCount = ref(0);
        const draftsCount = ref(0);
        const trashCount = ref(0);
        const archivedCount = ref(0);
        const starredCount = ref(0);
        const importantCount = ref(0);
        const spamCount = ref(0);
        const sentCount = ref(0);
        const router = useRoute();

        onMounted(async () => {
            await initializeMsalInstance();

            activeMailItem.value = 'inbox';
            await loadInbox();
            await loadStarred();
            await loadSpam();
            await loadImportant();
            await loadSent();
            await loadArchived();
            await loadDraft();
            await loadTrash();

            //initMail(allMails.value);
        });

        const loadStarred = async () => {
            var starred = await getStarred({ page: 10, skip: 0 });
            allStarred.value = starred?.messages;
            starredCount.value = starred?.total;
        };

        const loadImportant = async () => {
            var important = await getImportants({ page: 10, skip: 0 });
            allImportant.value = important?.messages;
            importantCount.value = important?.total;
        };

        const loadSpam = async () => {
            var spam = await getSpam({ page: 10, skip: 0 });
            allSpam.value = spam?.messages;
            spamCount.value = spam?.total;
        };

        const loadSent = async () => {
            var sent = await getSent({ page: 10, skip: 0 });
            allSent.value = sent?.messages;
            sentCount.value = sent?.total;
        };

        const loadArchived = async () => {
            var archived = await getArchived({ page: 10, skip: 0 });
            allArchived.value = archived?.messages;
            archivedCount.value = archived?.total;
        };

        const loadInbox = async () => {
            var mails = await getMails({ page: 10, skip: 0 });
            allMails.value = mails?.messages;
            mailsCount.value = mails?.total;
        };

        const loadDraft = async () => {
            var drafts = await getDrafts({ page: 10, skip: 0 });
            allDrafts.value = drafts?.messages;
            draftsCount.value = drafts?.total;
        };

        const loadTrash = async () => {
            
            var trash = await getTrash({ page: 10, skip: 0 });
            allTrash.value = trash?.messages;
            trashCount.value = trash?.total;
        };

        const sections = ref({
            inbox: true,
            draft: false,
            trash: false,
            detail: false,
            archived: false,
            starred: false,
            important: false,
            sent: false,
            spam: false,
            compose: false,
        });

        const handleNavigate = (section: any) => {debugger
            fromActiveMailItem.value = activeMailItem.value;
            activeMailItem.value = section.targetSection ? section.targetSection : section;
            if (section === 'inbox') {
                loadInbox();
                sections.value = { inbox: true, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: false };
            } else if (section === 'draft') {
                loadDraft();
                sections.value = { inbox: false, draft: true, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: false };
            } else if (section === 'trash') {
                loadTrash();
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: true, spam: false };
            } else if (section === 'starred') {
                loadStarred();
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: true, important: false, sent: false, trash: false, spam: false };
            } else if (section === 'important') {
                loadImportant();
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: true, sent: false, trash: false, spam: false };
            } else if (section === 'spam') {
                loadSpam();
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: true };
            } else if (section === 'sent') {
                loadSent();
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: false, starred: false, important: false, sent: true, trash: false, spam: false };
            } else if (section === 'archived') {
                loadArchived();
                sections.value = { inbox: false, draft: false, archived: true, detail: false, compose: false, starred: false, important: false, sent: false, trash: false, spam: false };
            } else if (section.targetSection === 'detail') {
                emailId.value = section.emailId;
                if (allDrafts.value.filter(d => d.id === section.emailId).length > 0) {
                    sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: true, starred: false, important: false, sent: false, trash: false, spam: false };
                }
                else {
                    sections.value = { inbox: false, draft: false, archived: false, detail: true, compose: false, starred: false, important: false, sent: false, trash: false, spam: false };
                }
            } else if (section === 'compose') {
                emailId.value = null;
                sections.value = { inbox: false, draft: false, archived: false, detail: false, compose: true, starred: false, important: false, sent: false, trash: false, spam: false };
            }
        };

        /*watch(
            () => router.path,
            (newPath) => {
                activeMailItem.value = newPath.split('/')[3];
            }
        );*/

        const initMail = async (mails) => {
            setBadgeValues(mails);
        };

        const getMails = async (object: any) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/mailFolders/inbox/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;

                if (object.searchValue) {
                    const encodedSearchValue = encodeURIComponent(object.searchValue);
                    url += `&$filter=(contains(subject, '${encodedSearchValue}') or contains(from/emailAddress/name, '${encodedSearchValue}'))`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    allMails.value = messages.value;
                    mailsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    console.error("Error al obtener correos:", error);
                    return null;
                }

            } else {
                return null;
            }
        };

        const getTrash = async (object: any) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/mailFolders/deletedItems/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;

                if (object.searchValue) {
                    const encodedSearchValue = encodeURIComponent(object.searchValue);
                    url += `&$filter=(contains(subject, '${encodedSearchValue}') or contains(from/emailAddress/name, '${encodedSearchValue}'))`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos eliminados y el conteo total
                    allTrash.value = messages.value;
                    trashCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    console.error("Error al obtener correos eliminados:", error);
                    return null;
                }

            } else {
                return null;
            }
        };

        const getArchived = async (object: any) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/mailFolders/archive/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;

                if (object.searchValue) {
                    const encodedSearchValue = encodeURIComponent(object.searchValue);
                    url += `&$filter=(contains(subject, '${encodedSearchValue}') or contains(from/emailAddress/name, '${encodedSearchValue}'))`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos archivados y el conteo total
                    allArchived.value = messages.value;
                    archivedCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    console.error("Error al obtener correos archivados:", error);
                    return null;
                }

            } else {
                return null;
            }
        };

        const getSpam = async (object: any) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/mailFolders/junkemail/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;

                if (object.searchValue) {
                    const encodedSearchValue = encodeURIComponent(object.searchValue);
                    url += `&$filter=(contains(subject, '${encodedSearchValue}') or contains(from/emailAddress/name, '${encodedSearchValue}'))`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos no deseados y el conteo total
                    allSpam.value = messages.value;
                    spamCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    console.error("Error al obtener correos no deseados:", error);
                    return null;
                }

            } else {
                return null;
            }
        };

        const getDrafts = async (object: any) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/messages?$top=${object.page}&$skip=${object.skip}&$count=true&$filter=isDraft eq true`;

                if (object.searchValue) {
                    const encodedSearchValue = encodeURIComponent(object.searchValue);
                    url += ` and (contains(subject, '${encodedSearchValue}') or contains(from/emailAddress/name, '${encodedSearchValue}'))`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de borradores y el conteo total
                    allDrafts.value = messages.value;
                    draftsCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    console.error("Error al obtener borradores:", error);
                    return null;
                }

            } else {
                return null;
            }
        };

        const getStarred = async (object: any) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/messages?$top=${object.page}&$skip=${object.skip}&$count=true&$filter=flag/flagStatus eq 'flagged'`;

                if (object.searchValue) {
                    const encodedSearchValue = encodeURIComponent(object.searchValue);
                    url += ` and (contains(subject, '${encodedSearchValue}') or contains(from/emailAddress/name, '${encodedSearchValue}'))`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos destacados y el conteo total
                    allStarred.value = messages.value;
                    starredCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    console.error("Error al obtener correos destacados:", error);
                    return null;
                }

            } else {
                return null;
            }
        };

        const getImportants = async (object: any) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/mailFolders/inbox/messages?$top=${object.page}&$skip=${object.skip}&$count=true&$filter=importance eq 'high'`;

                if (object.searchValue) {
                    const encodedSearchValue = encodeURIComponent(object.searchValue);
                    url += ` and (contains(subject, '${encodedSearchValue}') or contains(from/emailAddress/name, '${encodedSearchValue}'))`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos importantes y el conteo total
                    allImportant.value = messages.value;
                    importantCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    console.error("Error al obtener correos importantes:", error);
                    return null;
                }

            } else {
                return null;
            }
        };

        const getSent = async (object: any) => {
            const accessToken = await getAccessToken();
            if (accessToken) {
                let url = `https://graph.microsoft.com/v1.0/me/mailFolders/sentitems/messages?$top=${object.page}&$skip=${object.skip}&$count=true`;

                if (object.searchValue) {
                    const encodedSearchValue = encodeURIComponent(object.searchValue);
                    url += `&$filter=(contains(subject, '${encodedSearchValue}') or contains(from/emailAddress/name, '${encodedSearchValue}'))`;
                }

                try {
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json',
                        }
                    });

                    if (!response.ok) {
                        throw new Error(`Error en la petición: ${response.statusText}`);
                    }

                    const messages = await response.json();

                    // Actualizar la lista de correos enviados y el conteo total
                    allSent.value = messages.value;
                    sentCount.value = messages['@odata.count'];

                    return {
                        messages: messages.value,
                        total: messages['@odata.count']
                    };

                } catch (error) {
                    console.error("Error al obtener correos enviados:", error);
                    return null;
                }

            } else {
                return null;
            }
        };

        const setBadgeValues = (mails) => {debugger
            for (const mail of mails) {
                if (!mail.archived && !mail.trash && !mail.spam && !mail.sent && !mail.isDraft) {
                    setFilteredMails('inbox', mail);
                }
                Object.keys(mail).forEach((label) => {
                    if (mail[label] === true) {
                        setFilteredMails(label, mail);
                    }
                });
            }
        };

        const moveToTrash = async (message: any) => {
            try {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    // Verificar si message es un array (lista de correos)
                    if (Array.isArray(message)) {
                        // Construir solicitudes en lote para mover múltiples correos
                        const batchRequests = message.map((mail, index) => ({
                            id: `${index}`, // Un identificador único para cada solicitud (puede ser el índice)
                            method: "POST",
                            url: `/me/messages/${mail.id}/move`,
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: {
                                destinationId: "deleteditems" // Usamos directamente el nombre de la carpeta "Deleted Items"
                            }
                        }));

                        const batchResponse = await fetch(`https://graph.microsoft.com/v1.0/$batch`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                requests: batchRequests
                            })
                        });

                        if (batchResponse.ok) {
                            const results = await batchResponse.json();
                            results.responses.forEach(response => {
                                if (response.status !== 200) {
                                    console.error('Error moving message to trash:', response);
                                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al eliminar un correo. ' + response.body);
                                }
                            });
                            MessageService.showMessage(MessageType.Correcto, '', 'Correos enviados a elementos eliminados.');
                            await loadCurrentSection();
                        } else {
                            const errorText = await batchResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al eliminar correos: ' + errorText);
                        }
                    } else {
                        // Mover un solo correo
                        const moveResponse = await fetch(`https://graph.microsoft.com/v1.0/me/messages/${message.id}/move`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                destinationId: "deleteditems" // Usamos directamente el nombre de la carpeta "Deleted Items"
                            })
                        });

                        if (moveResponse.ok) {
                            await loadCurrentSection();
                            MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado a elementos eliminados.');
                        } else {
                            const messageError = await moveResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al eliminar el correo. ' + messageError);
                        }
                    }
                }
            } catch (error) {
                console.error('Error moving message to trash:', error);
            }
        };

        const moveToArchive = async (message: any) => {
            try {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    // Verificar si message es un array (lista de correos)
                    if (Array.isArray(message)) {
                        // Construir solicitudes en lote para mover múltiples correos
                        const batchRequests = message.map((mail, index) => ({
                            id: `${index}`, // Un identificador único para cada solicitud (puede ser el índice)
                            method: "POST",
                            url: `/me/messages/${mail.id}/move`,
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: {
                                destinationId: "archive" // Usamos directamente el nombre de la carpeta "Archive"
                            }
                        }));

                        const batchResponse = await fetch(`https://graph.microsoft.com/v1.0/$batch`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                requests: batchRequests
                            })
                        });

                        if (batchResponse.ok) {
                            const results = await batchResponse.json();
                            results.responses.forEach(response => {
                                if (response.status !== 200) {
                                    console.error('Error moving message to archive:', response);
                                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al archivar un correo. ' + response.body);
                                }
                            });
                            MessageService.showMessage(MessageType.Correcto, '', 'Correos archivados.');
                            await loadCurrentSection();
                        } else {
                            const errorText = await batchResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al archivar correos: ' + errorText);
                        }
                    } else {
                        // Mover un solo correo
                        const moveResponse = await fetch(`https://graph.microsoft.com/v1.0/me/messages/${message.id}/move`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                destinationId: "archive" // Usamos directamente el nombre de la carpeta "Archive"
                            })
                        });

                        if (moveResponse.ok) {
                            await loadCurrentSection();
                            MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado a archivados.');
                        } else {
                            const messageError = await moveResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al archivar el correo. ' + messageError);
                        }
                    }
                }
            } catch (error) {
                console.error('Error moving message to archive:', error);
            }
        };

        const loadCurrentSection = async () => {
            if (sections.value.inbox) {
                await loadInbox();
            }
            else if (sections.value.starred) {
                await loadStarred();
            }
            else if (sections.value.spam) {
                await loadSpam();
            }
            else if (sections.value.important) {
                await loadImportant();
            }
            else if (sections.value.sent) {
                await loadSent();
            }
            else if (sections.value.archived) {
                await loadArchived();
            }
            else if (sections.value.draft) {
                await loadDraft();
            }
            else if (sections.value.trash) {
                await loadTrash();;
            }
        };

        const getDetailMails = () => {debugger
            if (fromActiveMailItem.value == 'inbox') {
                return allMails.value;
            }
            else if (fromActiveMailItem.value == 'starred') {
                return allStarred.value;
            }
            else if (fromActiveMailItem.value == 'spam') {
                return allSpam.value;
            }
            else if (fromActiveMailItem.value == 'important') {
                return allImportant.value;
            }
            else if (fromActiveMailItem.value == 'sent') {
                return allSent.value;
            }
            else if (fromActiveMailItem.value == 'archived') {
                return allArchived.value;
            }
            else if (fromActiveMailItem.value == 'draft') {
                return allDrafts.value;
            }
            else if (fromActiveMailItem.value == 'trash') {
                return allTrash.value;
            }
        };

        const moveToSpam = async (message: any) => {
            try {
                const accessToken = await getAccessToken();
                if (accessToken) {
                    // Verificar si message es un array (lista de correos)
                    if (Array.isArray(message)) {
                        // Construir solicitudes en lote para mover múltiples correos
                        const batchRequests = message.map((mail, index) => ({
                            id: `${index}`, // Un identificador único para cada solicitud (puede ser el índice)
                            method: "POST",
                            url: `/me/messages/${mail.id}/move`,
                            headers: {
                                'Content-Type': 'application/json' // Asegurarse de que cada solicitud tenga el header Content-Type
                            },
                            body: {
                                destinationId: "junkemail" // Usamos el nombre de la carpeta "Junk Email"
                            }
                        }));

                        const batchResponse = await fetch(`https://graph.microsoft.com/v1.0/$batch`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                requests: batchRequests
                            })
                        });

                        if (batchResponse.ok) {
                            const results = await batchResponse.json();
                            results.responses.forEach(response => {
                                if (response.status !== 200) {
                                    console.error('Error moving message:', response);
                                    MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al mover un correo a no deseados. ' + response.body);
                                }
                            });
                            MessageService.showMessage(MessageType.Correcto, '', 'Correos enviados a no deseados.');
                            await loadCurrentSection();
                        } else {
                            const errorText = await batchResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al mover correos: ' + errorText);
                        }
                    } else {
                        // Mover un solo correo
                        const moveResponse = await fetch(`https://graph.microsoft.com/v1.0/me/messages/${message.id}/move`, {
                            method: 'POST',
                            headers: {
                                'Authorization': `Bearer ${accessToken}`,
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({
                                destinationId: "junkemail"
                            })
                        });

                        if (moveResponse.ok) {
                            MessageService.showMessage(MessageType.Correcto, '', 'Correo enviado a no deseados.');
                            await loadCurrentSection();
                        } else {
                            const messageError = await moveResponse.text();
                            MessageService.showMessage(MessageType.Error, 'Error de validación', 'Error al mover el correo a no deseados. ' + messageError);
                        }
                    }
                }
            } catch (error) {
                console.error('Error moving message to junk:', error);
            }
        };

        const setFilteredMails = (type, mail) => {
            if (!filteredMails.value[type]) {
                filteredMails.value[type] = [];
            } else {
                filteredMails.value[type].push(mail);
            }
        };

        const sidebarItems = computed(() => {debugger
            //const mails: any = filteredMails.value;

            return [
                { label: 'Bandeja de entrada', icon: 'pi pi-inbox', badge: allMails.value?.length || 0, routerLink: 'inbox' },
                { label: 'Seguimiento', icon: 'pi pi-star', badge: allStarred.value?.length || 0, routerLink: 'starred' },
                { label: 'No deseado', icon: 'pi pi-ban', badge: allSpam.value?.length || 0, routerLink: 'spam' },
                { label: 'Importantes', icon: 'pi pi-bookmark', badge: allImportant.value?.length || 0, routerLink: 'important' },
                { label: 'Enviados', icon: 'pi pi-send', badge: allSent.value?.length || 0, routerLink: 'sent' },
                { label: 'Archivados', icon: 'pi pi-book', badge: allArchived.value?.length || 0, routerLink: 'archived' },
                { label: 'Borradores', icon: 'pi pi-pencil', badge: allDrafts.value?.length || 0, routerLink: 'draft' },
                { label: 'Papelera', icon: 'pi pi-trash', badge: allTrash.value?.length || 0, routerLink: 'trash' }
            ];
        });

        const onDeleteMail = async (selectedMails) => {debugger
            filteredMails.value = {};
            const toastDetail = selectedMails.length > 0 ? 'Mails deleted' : 'Mail deleted';

            findAndApplyAction('trash', selectedMails);

            toast.add({ severity: 'info', summary: 'Info', detail: toastDetail, life: 3000 });
            initMail(allMails.value);
        };

        const onSpamMail = async (selectedMails) => {
            filteredMails.value = {};

            selectedMails.forEach((selectedMail) => {
                allMails.value.find((mail) => mail.id === selectedMail.id).spam = true;
            });
            initMail(allMails.value);
        };

        const onArchiveMail = async (selectedMails) => {
            filteredMails.value = {};

            findAndApplyAction('archived', selectedMails);

            initMail(allMails.value);
        };

        const onChangeMailType = async (type, selectedMail, value) => {
            filteredMails.value = {};

            allMails.value.find((mail) => mail.id === selectedMail.id)[type] = value;

            initMail(allMails.value);
        };

        const findAndApplyAction = (action, selectedMails) => {
            if (selectedMails.length > 0) {
                selectedMails.forEach((selectedMail) => {
                    allMails.value.find((mail) => mail.id === selectedMail.id)[action] = true;
                });
            } else {
                allMails.value.find((mail) => mail.id === selectedMails.id)[action] = true;
            }
        };

        const showReplyDialog = (mail) => {
            dialogVisible.value = true;
            mailDetail.value = mail;
        };

        const onSaveReplyMail = (mail) => {
            dialogVisible.value = false;
            mailDetail.value = null;

            filteredMails.value = {};
            allMails.value.push(mail);

            initMail(allMails.value);
        };

        const onSendNewMail = (mail) => {
            filteredMails.value = {};
            allMails.value.push(mail);

            initMail(allMails.value);
        };

        const onChangeDialogVisibility = (isVisible) => {
            dialogVisible.value = isVisible;
        };

        onUnmounted(() => {
            baseOnUnmount();
        })

        return {
            BaseControlTypeConst,
            getPropertyBooleanValue,
            vmodel,
            EmailTypeConst,
            getPropertyValue,
            getPropertyNumberValue,
            loaded,
            canDoOperation,
            ObjectGroupConst,
            componentKey,
            allMails,
            allTrash,
            allDrafts,
            allArchived,
            allStarred,
            allImportant,
            allSpam,
            allSent,
            filteredMails,
            activeMailItem,
            moveToTrash,
            moveToArchive,
            moveToSpam,
            onSpamMail,
            onArchiveMail,
            onChangeMailType,
            showReplyDialog,
            onSaveReplyMail,
            onSendNewMail,
            sidebarItems,
            dialogVisible,
            mailDetail,
            onChangeDialogVisibility,
            handleNavigate,
            sections,
            emailId,
            getMails,
            getDrafts,
            getTrash,
            getArchived,
            getSpam,
            getImportants,
            getSent,
            getStarred,
            mailsCount,
            draftsCount,
            trashCount,
            archivedCount,
            starredCount,
            importantCount,
            spamCount,
            sentCount,
            getDetailMails,
        };
    },
});
</script>
<style scoped></style>
