<!-- eslint-disable no-self-compare -->
<template>
  <div>
      <form @submit.prevent="handleSubmit(!v$.$invalid)">
          <div class="limiter">
              <div class="container-login100">
                  <div class="wrap-login100">

                      <span class="login100-form-logo">
                          <i class="zmdi zmdi-landscape"></i>
                          <img :src="getLogoUrl" style="width: 100px" />
                          <!--Logo Base64 -->
                          <!--<img v-bind:src="'data:image/png;base64,'+ appCfg.logopng" style="width: 100px" />-->
                      </span>
                      <span class="login100-form-title p-b-34 p-t-27 mb-5">

                      </span>
                      <div class="txt1">
                          Introduzca su e-mail.
                      </div>
                      <span class="p-float-label wrap-input100 validate-input">
                          <input id="username" class="input100" type="text" name="usernameInput" placeholder="Usuario"
                              v-model="request.email" :class="{ 'p-invalid': v$.email.$invalid && submitted }" />
                          <label style="color:white;font-size: 1.5rem" class="pi pi-user" for="username" />
                      </span>
                      <span>
                          <small v-if="(v$.email.$invalid && submitted) || v$.email.$pending.$response"
                              class="p-error">{{
                                  v$.email.required.$message }}</small>
                      </span>
                      <div class="txt1">
                          Confirme su e-mail y pulse "Restablecer". Se le enviará un correo con las instrucciones para
                              restablecer su contraseña
                      </div>
                      <span class="p-float-label wrap-input100 validate-input">
                          <input id="username" class="input100" type="text" name="usernameInput" placeholder="Usuario"
                              v-model="request.emailconfirm" :class="{ 'p-invalid': v$.emailconfirm.$invalid && submitted }" />
                          <label style="color:white;font-size: 1.5rem" class="pi pi-user" for="username" />
                      </span>
                      <span>
                          <small v-if="(v$.emailconfirm.$invalid && submitted) || v$.emailconfirm.$pending.$response"
                              class="p-error">{{
                                  v$.emailconfirm.required.$message }}</small>
                      </span>
                      <div class="container-login100-form-btn">
                          <button class="login100-form-btn" id="submitButton" type="submit" @click="onSubmitForgotPassword">
                              Restablecer
                          </button>
                      </div>
                      <div class="login-methods mt-4">
                        <button v-if="signinWiths.includes(CatalogConfigurationConst.SIGNINWITHMICROSOFT)" class="microsoft-button" @click="vincularMicrosoft" style="background-color: #0078d4; color: white; border: none; padding: 10px; border-radius: 5px; cursor: pointer;">
                            <i class="pi pi-microsoft" style="margin-right: 8px;"></i> Vincular cuenta Microsoft
                        </button>
                        <button v-if="signinWiths.includes(CatalogConfigurationConst.SIGNINWITHGOOGLE)" class="google-button" @click="vincularGoogle" style="background-color: #db4437; color: white; border: none; padding: 10px; border-radius: 5px; cursor: pointer; margin-bottom: 10px;">
                            <i class="pi pi-google" style="margin-right: 8px;"></i> Vincular cuenta Google
                        </button>
                      </div>
                      <div class="text-center p-t-90 mt-3">
                          <a class="txt1" href="#" @click="back">
                              Volver a la página de acceso.
                          </a>
                      </div>
                  </div>
              </div>
          </div>
      </form>
  </div>

        
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-unused-vars */
import { computed, ref, inject, onMounted } from 'vue'
import { useStore } from 'vuex'
import { Container } from 'inversify';
import Environment from '../../../../../common/infrastructure/funciones/environment'
import { appConfig } from "../../domain/appConfig";
import Button from 'primevue/button'
import MessageService, { MessageType } from '@ilinium/shared/src/common/infrastructure/servicios/MessageService';
import HelperCommon from '@ilinium/shared/src/common/infrastructure/funciones/HelperCommon';
import { helpers, required } from "@vuelidate/validators";
import { resetPass } from "../../domain/resetPass";
import useVuelidate from "@vuelidate/core";
import HelperLoading from "../../../../../common/infrastructure/funciones/HelperLoading";
import {
  Actions,
  Mutations,
} from "../../../../../common/infrastructure/almacen/enums/StoreEnums";
import CatalogConfigurationConst from '@ilinium/shared/src/entidades/builder/catalog/domain/const/CatalogConfigurationConst';
import { msalInstance, initializeMsalInstance } from "./authConfig";
import { AuthenticationResult, AccountInfo } from "@azure/msal-browser";

declare const google: any;

export default {
  components: { Button },
  name: 'forgotpassword',
  props:
    {
        container: {
            type: Object as () => Container
        },
        getLogoUrl: {
            type: String,
            default: ''
        },
        appCfg: {
          type: Object as () => appConfig
        },
    },
  emits: ['isForgetPassChild'],
  setup (props, { emit }) {
    const store = useStore()
    const emailControl = ref < HTMLElement | null > (null)
    const emailConfirmControl = ref < HTMLElement | null > (null)
    const email = ref('')
    const emailconfirm = ref('')
    const ipublicPath = inject('publicPath')
    const submitted = ref(false);
    
    let my_color1 = props.appCfg.color1;
    let my_color2 = props.appCfg.color2;

    const signinWiths = computed(() => {
        return store.getters.getAppConfig.filter((x) => x.idKeyType == CatalogConfigurationConst.SIGNINWITH)[0]?.keyValue ?? '';
    });

    /* const user_req: Partial<Usuario> = {
            email: ''
        };

        const request = ref<forgetPasswordRequest>({
            user: user_req as any,
            remoteApplication: 'Portal',
            remoteIp: '',
            requestedUrl: '',
        }); */

    function validateData () {
        if (!validateEmail(email.value)) {
          MessageService.showMessage(MessageType.Error, 'Error de validación', 'Debe indicar un e-mail válido.');
          return false;
        }
        if (request.value.emailconfirm === null || request.value.emailconfirm === '' ||
            request.value.email === null || request.value.email === ''
        ) {
          return false
        }
        if (request.value.email != request.value.emailconfirm) {
          MessageService.showMessage(MessageType.Error, 'Error de validación', 'Las direcciones de e-mail no coinciden.');
          return false;
        }

      return true
    }

    const back = (event: any) => {
      
      emit('isForgetPassChild', false);
    }

    function validateEmail (email: string) {
      return HelperCommon.validarEmail(email)
    } 

    const onSubmitForgotPassword = () => {
      submitted.value = true;
      if (validateData()) {
        forgotPassword(null, null);
      }
    }

    function forgotPassword(microsoftEmail: string | null, googleEmail: string | null) {
      store.dispatch(Actions.FORGOT_PASSWORD, [request.value.email, microsoftEmail, googleEmail, Environment.APPID, Environment.APPVERSION, props.container]).then(() => {
          MessageService.showToast(MessageType.Correcto, '', 'Se ha enviado el correo de reestablecimiento de contraseña correctamente.');
          emit('isForgetPassChild', false);
          //router.push({ name: 'forgotpassword' });
      }).finally(() => {
          HelperLoading.hideLoading();
      });
    }

    const getLogoUrl = computed(() => {
      return Environment.URL_LOGO_PNG;      
    })

    const request = ref<resetPass>({
      email: "",
      emailconfirm: "",
      password: "",
      confirmpass: ""
    });

    const customEmail = (value: string) => {
      return HelperCommon.validarEmail(value);
    };

    const rules = {
      email: {
        required: helpers.withMessage("El email es obligatorio", required),
        customEmail: helpers.withMessage("Correo no válido", customEmail)
      },
      emailconfirm: {
        required: helpers.withMessage("Confirmar el email es obligatorio", required),
        customEmail: helpers.withMessage("Correo no válido", customEmail),
      },
    };

    const v$ = useVuelidate(rules, request);

    const vincularMicrosoft = async () => {
      try {
        
        submitted.value = true;
        if (validateData()) {
          // Verificar si hay una interacción pendiente
          await msalInstance.handleRedirectPromise().catch(error => {
              console.error("Error en handleRedirectPromise", error);
          });

          const loginResponse: AuthenticationResult = await msalInstance.loginPopup({
              scopes: ["user.read"], // Puedes especificar los permisos que necesitas
          });

          // Obtener la información de la cuenta autenticada
          const account: AccountInfo | null = msalInstance.getAllAccounts()[0];
          if (account && account.idToken) {
            forgotPassword(account.username, null);
          }
        }
      } catch (error) {
          console.error("Error al iniciar sesión", error);
      }
    };

    const vincularGoogle = async () => {
      submitted.value = true;
      if (validateData()) {
        if (typeof google === 'undefined') {
            console.error('Google Identity Services not loaded');
            return;
        }

        google.accounts.id.initialize({
            client_id: process.env.VUE_APP_GOOGLE_APPID,
            ux_mode: 'popup', // Puedes usar 'redirect' si prefieres una redirección en lugar de un popup
            scope: 'profile email', // Añade los scopes necesarios
            callback: handleCredentialResponse
        });

        google.accounts.id.prompt(); // Mostrar el prompt de inicio de sesión
      }
    }

    function handleCredentialResponse(response: any) {
        console.log('ID Token: ', response.credential);
        // Procesar el token de ID aquí
        try {
            var token = response.credential;
            const base64Url = token.split('.')[1]; // El payload del JWT está en la segunda parte del token
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            if (response && response.credential) {
              forgotPassword(null, (JSON.parse(jsonPayload)).email);
            }

        } catch (error) {
            console.error("Error al iniciar sesión", error);
        }
    }

    onMounted(async () => {
        await initializeMsalInstance();
        // Verificar si el usuario ya está autenticado al cargar la aplicación
        const account = msalInstance.getAllAccounts()[0];
    })

    return {
      emailControl,
      emailConfirmControl,
      emailconfirm,
      email,
      onSubmitForgotPassword,
      getLogoUrl,
      ipublicPath,
      my_color1,
      my_color2,
      back,
      v$,
      submitted,
      request,
      signinWiths,
      CatalogConfigurationConst,
      vincularMicrosoft,
      vincularGoogle,
    }
  }
}
</script>

<style lang="scss" scoped src="./css/login.scss"></style>
<style lang="css" scoped src="./css/theme.css"></style>
<style lang="css" scoped src="./css/signinWith.css"></style>
<style lang="css" scoped >
    .wrap-login100 {
        width: 500px;
        border-radius: 10px;
        overflow: hidden;
        padding: 55px 55px 37px;
        background: #9152f8;
        background: -webkit-linear-gradient(bottom, v-bind(my_color1), v-bind(my_color2));
        background: -o-linear-gradient(bottom, v-bind(my_color1), v-bind(my_color2));
        background: -moz-linear-gradient(bottom, v-bind(my_color1), v-bind(my_color2));
        background: linear-gradient(v-bind(my_color1), v-bind(my_color2));
        opacity: 0.8;
    }
</style>
