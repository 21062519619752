export default class CatalogConfigurationConst {
 
    static COLOR1='appconfig-color1'
    static COLOR2='appconfig-color2'
    static LANGUAGE='appconfig-language'
    static LOGOPNG='appconfig-logopng'
    static LOGOSVG='appconfig-logosvg'
    static TEMPLATE='appconfig-template'
    static FAVICON='appconfig-favicon'
    static TEMPLATETHEME='appconfig-template-theme'
    static TEMPLATETHEMESTYLE='appconfig-template-theme-style'
    static TEMPLATECONFIG='appconfig-template-config'
    static TEMPLATECONFIGSIMPLE='appconfig-template-config-simple'
    static URLREPORTLAUNCHER='appconfig-template-urlreportlauncher'
    static ISAPPEXTERNAL='appconfig-isapp-external'
    static SIGNINWITH='appconfig-signinwith'
    static SIGNINWITHGOOGLE='appconfig-signinwith-google'
    static SIGNINWITHMICROSOFT='appconfig-signinwith-microsoft'
    static FOOTER='appconfig-footer'
    static RENDER='appconfig-render'
    static COMP_LAYOUT='appconfig-componentlayout'

    }
    