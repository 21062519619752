import { PublicClientApplication, Configuration } from "@azure/msal-browser";
import { AccountInfo } from "@azure/msal-browser/dist";

const msalConfig: Configuration = {
  auth: {
    clientId: "" + process.env.VUE_APP_MICROSOFT_APPID, // ID de la aplicación registrada en Azure AD
    authority: "https://login.microsoftonline.com/" + process.env.VUE_APP_MICROSOFT_TENANTID + "/v2.0", // URL de tu inquilino en Azure
    redirectUri: process.env.VUE_APP_MICROSOFT_URLREDIRECT, // Redirigir a la URL de tu aplicación después del inicio de sesión
  },
  cache: {
    cacheLocation: "localStorage", // Puedes usar localStorage para persistir la sesión
    storeAuthStateInCookie: true, // Recomendado para IE11/Edge
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export async function initializeMsalInstance() {
  await msalInstance.initialize();
}

export async function authMicrosoft(): Promise<AccountInfo | null> {
  try {
    // Verificar si hay una interacción pendiente
    await msalInstance.handleRedirectPromise().catch(error => {
        console.error("Error en handleRedirectPromise", error);
    });

    const account = msalInstance.getAllAccounts()[0];
    let tokenResponse;

    if (account) {
        // Obtener el token de manera silenciosa si el usuario ya está autenticado 
        const silentTokenRequest = {
            scopes: ["user.read", "Mail.ReadWrite"],
            account: account,
        };
        tokenResponse = await msalInstance.acquireTokenSilent(silentTokenRequest);
    } else {
        // Mostrar el popup para autenticarse si no hay cuenta autenticada
        tokenResponse = await msalInstance.loginPopup({
            scopes: ["user.read", "Mail.ReadWrite"],
        });
    }

    return msalInstance.getAllAccounts()[0];
  } catch (error) {
      console.error("Error al iniciar sesión", error);
      return null;
  }
}

export async function getAccessToken(): Promise<string | null> {
  try {
    // Verificar si hay una interacción pendiente
    await msalInstance.handleRedirectPromise().catch(error => {
      console.error("Error en handleRedirectPromise", error);
    });

    const account = msalInstance.getAllAccounts()[0];
    let tokenResponse;

    if (account) {
      // Intentar obtener el token de manera silenciosa
      const silentTokenRequest = {
        scopes: ["user.read", "Mail.ReadWrite"], // Pedir el permiso correcto para leer los correos
        account: account,
      };

      tokenResponse = await msalInstance.acquireTokenSilent(silentTokenRequest);
    } else {
      // Mostrar el popup para autenticarse si no hay cuenta autenticada
      tokenResponse = await msalInstance.loginPopup({
        scopes: ["user.read", "Mail.ReadWrite"], // Pedir el permiso correcto para leer los correos
      });
    }

    // Devolver el accessToken en lugar del idToken
    return tokenResponse.accessToken;
  } catch (error) {
    console.error("Error al iniciar sesión", error);
    return null;
  }
}